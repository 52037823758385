import './pick-ban-info.scss'
import { IProps } from './pick-ban-info.model'
import { Loader } from '../../componentsKit/loader/Loader'

export function PickBanInfo(props: IProps) {
  // classes
  const pickBanInfoClasses: string[] = [
    'qc-pick-ban-info',
    props.waiting ? 'qc-pick-ban-info-waiting' : `qc-pick-ban-info-${props.step.does}`,
    props.classes || '',
  ].filter((x) => x !== '')

  const doesList: { [key: string]: string } = {
    pick: 'picked',
    ban: 'banned',
    won: 'won',
  }

  return (
    <div className={pickBanInfoClasses.join(' ')} style={props.css}>
      <div className="qc-pick-ban-info__body">
        {props.waiting !== true ? (
          <div className="d-flex flex-wrap">
            <span className="qc-pick-ban-info__who">{props.step.player}</span>
            <div className="d-flex">
              <span className="qc-pick-ban-info__does">{doesList[props.step.does] || ''}</span>
              <span className="qc-pick-ban-info__target">{props.step.name}</span>
            </div>
          </div>
        ) : (
          <>
            <div className="d-flex flex-wrap">
              <span className="qc-pick-ban-info__waiting">Waiting for</span>
              <div className="d-flex flex-wrap">
                <span className="qc-pick-ban-info__who">{props.step.player} to</span>
                <span className="qc-pick-ban-info__does">{props.step.does}</span>
                <span className="qc-pick-ban-info__target">the {props.step.what}</span>
              </div>
            </div>
            <div className="d-flex">
              <Loader classes="qc-pick-ban-info__loader" />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
