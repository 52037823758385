import { CSSProperties } from 'react'

export enum LoaderTypes {
  Default = 'default',
}

export interface ICSSProps extends CSSProperties {
  '--thickness'?: string
  '--size'?: string
  '--speedMS'?: string
  '--color'?: string
  '--backColor'?: string
}

export interface IProps {
  classes?: string
  css?: ICSSProps
  type?: LoaderTypes
}

export const defaultProps: IProps = {
  type: LoaderTypes.Default,
}

export function mergePropsWithDefault(
  props: IProps,
  defaults: IProps = defaultProps
): IProps {
  const cssProps = { ...defaults.css, ...props.css }
  return {
    ...defaults,
    ...props,
    css: cssProps,
  } as IProps
}
