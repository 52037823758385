import { QCButtonTypes, IProps, mergePropsWithDefault } from './button.models'
import './button.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function Button(props: IProps) {
  // props
  props = mergePropsWithDefault(props)
  // classes
  const buttonClasses: string[] = [
    'qc-button',
    props.type ? `qc-button-${props.type}` : '',
    !props.disabledWithoutChangeStyles && props.disabled
      ? 'qc-button-disabled qc-button-transition-none'
      : '',
    `qc-button-${props.color}`,
    props.classes || '',
  ].filter((x) => x !== '')

  return (
    <button
      className={buttonClasses.join(' ')}
      style={props.css}
      disabled={
        props.disabledWithoutChangeStyles || props.disabled ? true : false
      }
      onClick={props.onClick}
    >
      {props.iconLeft && (
        <FontAwesomeIcon
          icon={props.iconLeft!}
          className="qc-button__icon qc-button__icon-left"
        />
      )}
      {props.text}
      {props.iconRight && (
        <FontAwesomeIcon
          icon={props.iconRight!}
          className="qc-button__icon qc-button__icon-right"
        />
      )}
    </button>
  )
}
