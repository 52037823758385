import React from 'react'
import './frame.scss'
import { Navbar } from './navbar/Navbar'

interface IFrame {
  children: React.ReactNode
}

export function Frame({ children }: IFrame) {
  return (
    <div className="page">
      <header>
        <Navbar />
      </header>
      <main>{children}</main>
      <footer></footer>
    </div>
  )
}
