import './navbar.scss'
import { NavLink } from 'react-router-dom'
import { ButtonSteamSign } from '../../button-steam-sign/ButtonSteamSign'
import { useContext } from 'react'
import { AuthContext } from '../../../context/AuthContext'
import { SteamUserMenu } from '../../steam-user-menu/SteamUserMenu'
import { DiscordSignButton } from '../../../componentsTest/discordSignButton/DiscordSignButton'
import { DiscordUserMenu } from '../../../componentsTest/discordUserMenu/DiscordUserMenu'

export function Navbar() {
  const auth = useContext(AuthContext)
  return (
    <div className="navbar">
      <div className="navbar-container">
        <div className="title">QC BEST OF</div>
        <nav>
          <NavLink to="/" className="nav-item">
            Create
          </NavLink>
          <NavLink to="/sessions" className="nav-item ml-1">
            Sessions
          </NavLink>
          <NavLink to="/contacts" className="nav-item ml-1">
            Contacts
          </NavLink>
          {auth.isAuthenticated ? <DiscordUserMenu /> : <DiscordSignButton />}
        </nav>
      </div>
    </div>
  )
}
