import { IContainerProps as IProps } from './pool-info.model'
import './pool-info-container.scss'

export function PoolInfoContainer(props: IProps) {
  const poolInfoContainerClasses: string[] = [
    'qc-pool-info-container',
    `qc-pool-info-container-${props.status}`,
    props.classes || '',
  ].filter((x) => x !== '')

  return (
    <div className={poolInfoContainerClasses.join(' ')} style={props.css}>
      <div className="qc-pool-info-container__body">
        <div className="qc-pool-info-container__body-title">
          {props.status} {props.type}
        </div>
        {props.children}
      </div>
    </div>
  )
}
