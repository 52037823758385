import { CSSTransition } from 'react-transition-group'
import { Alert } from '../componentsKit/alert/Alert'
import { Button } from '../componentsKit/button/Button'
import { Loader } from '../componentsKit/loader/Loader'
import { MyTemplate } from '../components/my-template/MyTemplate'
import { ITemplate, useMyTemplates } from '../hooks/my-templates.hook'
import '../scss/qc.scss'
import { faPlus as NewTemplateIcon } from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect, useContext } from 'react'
import { Modal } from '../componentsKit/modal/Modal'
import { ModalContext } from '../componentsKit/modal/ModalContext'
import { useHttp } from '../hooks/http.hook'
import { AuthContext } from '../context/AuthContext'

export const MyTemplatesPage = () => {
  // hooks - context
  const { open, close } = useContext(ModalContext)
  const { token } = useContext(AuthContext)
  // hooks - custom
  let { templates, loading, error } = useMyTemplates()
  const { request } = useHttp()
  // hooks - navigate
  const navigate = useNavigate()
  // hooks - state
  const [templateList, setTemplateList] = useState<ITemplate[] | null>(null)
  const [templateForDelete, setTemplateForDelete] = useState({
    id: 0,
    name: '',
  })
  // hooks - effect
  useEffect(() => {
    if (!loading && !error && templates) {
      setTemplateList(templates)
    }
  }, [templates])

  // handlers
  const newTemplateHandler = () => {
    navigate('/my-templates/0')
  }
  const openDeleteTemplateConfirm = (id: number, name: string) => {
    setTemplateForDelete({ id, name })
    open()
  }
  const deleteTemplate = async () => {
    try {
      const body = { id: templateForDelete.id }
      const req = await request(
        '/my-templates/deleteMyTemplate',
        'POST',
        body,
        {
          Authorization: `Bearer ${token}`,
        }
      )
      if (req.error) {
        console.error(`${req.error}: ${req.message}`)
      } else {
        if (templateList) {
          setTemplateList(
            templateList.filter((x) => x.id !== templateForDelete.id)
          )
        }
      }
    } catch (e) {}
    close()
  }

  return (
    <div className="qc-container">
      <div className="qc-content">
        <>
          <div className="qc-content__header">
            <h1>
              My templates
              <CSSTransition
                in={loading}
                timeout={300}
                classNames={'qc-loader-container'}
                unmountOnExit
              >
                <div className="qc-loader-container qc-content__header-loader">
                  <Loader />
                </div>
              </CSSTransition>
            </h1>
          </div>
          {error ? (
            <Alert color="red" title="Error" description={error} />
          ) : (
            <CSSTransition
              in={!loading}
              timeout={500}
              classNames={'use-css-transition'}
              unmountOnExit
            >
              <div className="use-css-transition mb-2">
                <Button
                  text="New template"
                  color="green"
                  iconLeft={NewTemplateIcon}
                  onClick={newTemplateHandler}
                />
                {templateList ? (
                  templateList.map((template) => (
                    <MyTemplate
                      name={template.name}
                      id={template.id}
                      onDelete={openDeleteTemplateConfirm}
                      classes="mt-2"
                    />
                  ))
                ) : (
                  <></>
                )}
              </div>
            </CSSTransition>
          )}
        </>
      </div>
      <Modal onClose={close} classes="qc-my-template-modal">
        <div className="d-flex flex-column">
          <div className="qc-my-template-modal__title">
            Delete template
            <span className="qc-my-template-modal__title-name">
              {templateForDelete.name}
            </span>
            ?
          </div>
          <div className="qc-my-template-modal__buttons">
            <Button text="Yes, Delete" color="green" onClick={deleteTemplate} />
            <Button text="No, Cancel" color="red" onClick={close} />
          </div>
        </div>
      </Modal>
    </div>
  )
}
