import { CSSProperties } from 'react'

export interface IProps {
  classes?: string
  title?: string
  description?: string
  css?: CSSProperties
  color?: string
}

export const defaultProps: IProps = {
  color: 'green',
}

export function mergePropsWithDefault(
  props: IProps,
  defaults: IProps = defaultProps
): IProps {
  const cssProps = { ...defaults.css, ...props.css }
  return {
    ...defaults,
    ...props,
    css: cssProps,
  } as IProps
}
