import { IItemProps as IProps, mergeItemPropsWithDefault } from './radio.models'
import './radio.scss'

export function RadioItem(props: IProps) {
  // props
  props = mergeItemPropsWithDefault(props)
  // classes
  const radioItemClasses: string[] = [
    'qc-radio',
    props.checked ? 'qc-radio-checked' : '',
    props.classes || '',
  ].filter((x) => x !== '')
  // methods
  const check = () => {
    props.onCheckedItemChange
      ? props.onCheckedItemChange(props.dataItem!)
      : void 0
  }

  return (
    <div
      className={radioItemClasses.join(' ')}
      onClick={!props.disabled ? check : void 0}
    >
      <div className="qc-radio__circle">
        <div className="qc-radio__circle-outer"></div>
        <div className="qc-radio__circle-inner"></div>
      </div>
      <div className="qc-radio__description">
        {props.dataItem![props.valueField!]}
      </div>
    </div>
  )
}
