import { CSSProperties } from 'react'

export interface IProps {
  classes?: string
  children: React.ReactNode
  closeButton?: boolean
  css?: CSSProperties
  cssWindow?: CSSProperties
  //
  onClose?: () => void
}

export const defaultProps: IProps = {
  children: '<div></div>',
  closeButton: false,
}

export function mergePropsWithDefault(
  props: IProps,
  defaults: IProps = defaultProps
): IProps {
  const cssProps = { ...defaults.css, ...props.css }
  const cssWindowProps = { ...defaults.cssWindow, ...props.cssWindow }
  return {
    ...defaults,
    ...props,
    css: cssProps,
    cssWindow: cssWindowProps,
  } as IProps
}
