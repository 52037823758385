import '../scss/qc.scss'

export const ContactsPage = () => {
  return (
    <div className="qc-container">
      <div className="qc-content">
        <div className="qc-content__header">
          <h1>Contacts</h1>
        </div>
        <div className="qc-contacts-info">
          <span>Discord: </span>
          <a className="qc__link" href="https://discord.com/users/311887154030575627/" target="_blank">
            Zabivalkin
          </a>
        </div>
      </div>
    </div>
  )
}
