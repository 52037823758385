import { useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { faCaretDown as ArrowIcon, faQuestion as questionIcon } from '@fortawesome/free-solid-svg-icons'
import { faVk as vkIcon, faTwitch as twitchIcon, IconDefinition } from '@fortawesome/free-brands-svg-icons'
import buttonStyles from './Button.module.scss'
import dropdownStyles from './Dropdown.module.scss'
import { Button } from '../../componentsKitTest/button/Button'
import { Dropdown } from '../../componentsKitTest/dropdown/Dropdown'
import { DropdownFrom } from '../../componentsKitTest/dropdown/models'
import { IStream } from '../../models/session.model'
import { streamsImage } from '../../images/streams/streams'

export function StreamsMenu({ streams }: { streams: IStream[] }) {
  // context
  const auth = useContext(AuthContext)
  // hooks
  const [opened, setOpened] = useState<boolean>(false)
  // methods
  const open = () => {
    setOpened(true)
  }
  const close = () => {
    setOpened(false)
  }
  const toggle = () => {
    opened ? close() : open()
  }

  const getServiceProps = (service: string): { color: string, image: string } | null => {
    const services = {
      twitch: {
        color: 'purple',
        image: streamsImage.twitch,
      },
      vkplay: {
        color: 'blue',
        image: streamsImage.vk,
      },
    } as {[key: string]: { color: string, image: string } }
    return services[service] || null
  }
  //handlers
  const openedChangeHandler = (opened: boolean) => {
    setOpened(opened)
  }
  const goToStreamHandler = (link: string) => {
    window.open(link, '_blank')
  }
  // vars
  const streamsHash = streams.reduce((acc, cur) => {
    const { lang } = cur
    if (!(lang in acc)) {
      acc[lang] = []
    }
    acc[lang].push(cur)
    return acc
  }, {} as { [key: string]: IStream[] })

  return (
    <div>
      <Button classes={buttonStyles.root} color="orange" text="Streams" iconRight={ArrowIcon} onClick={toggle} />
      <Dropdown
        opened={opened}
        onOpenedChange={openedChangeHandler}
        from={DropdownFrom.Bottom}
        css={{ '--top': '20px', '--left': 'calc(100% - 164px)' }}
      >
        <div className={dropdownStyles.root}>
          {Object.entries(streamsHash).map(([lang, streams]) => {
            const nodes: React.ReactNode[] = []
            nodes.push(<div className={dropdownStyles.lang}>{lang}</div>)
            streams.forEach((stream) => {
              const { link, name, service } = stream
              const serviceProps = getServiceProps(service)
              if(serviceProps){
                nodes.push(
                  <Button
                    color={serviceProps.color}
                    text={name}
                    classes={dropdownStyles.button}
                    onClick={() => goToStreamHandler(link)}
                    imageRight={serviceProps.image}
                  />
                )
              }
            })

            return nodes
          })}
        </div>
      </Dropdown>
    </div>
  )
}
