import { IProps } from './backdrop.model'
import { CSSTransition } from 'react-transition-group'
import styles from './Backdrop.module.scss'

export function Backdrop(props: IProps) {
  // classes
  const classes: string = [styles.root, props.classes]
    .filter((x) => x !== '')
    .join(' ')

  // handlers
  const clickHandler = (e: React.MouseEvent<HTMLInputElement>) => {
    const target = e.target as HTMLButtonElement
    target.dataset.type === 'close' ? props.onClose!() : void 0
  }

  return (
    <>
      <CSSTransition
        in={props.showed}
        timeout={200}
        classNames={{
          enter: styles.enter,
          enterActive: styles.enterActive,
          exitActive: styles.exitActive,
        }}
        unmountOnExit
      >
        <div
          className={classes}
          style={props.css}
          onClick={clickHandler}
          data-type="close"
        >
          {props.children !== null ? props.children : <></>}
        </div>
      </CSSTransition>
    </>
  )
}
