import { useState, useEffect } from 'react'
import { ISessionResponseData } from '../models/session.model'

const url =
  process.env.NODE_ENV !== 'production'
    ? `ws://${process.env.REACT_APP_SERVER_HOST_DEV}:${process.env.REACT_APP_SERVER_PORT_DEV}`
    : `wss://${window.location.hostname}`

function useWebSocket(socketUrl: string = url || '') {
  const [data, setData] = useState<ISessionResponseData>()
  const [send, setSend] = useState(() => (data: string) => undefined)
  const [ready, setReady] = useState(false)

  useEffect(() => {
    const ws = new WebSocket(socketUrl)
    ws.onopen = () => {
      setReady(true)

      setSend(() => {
        return (data: string): any => {
          try {
            ws.send(data)
            return true
          } catch (err) {
            return false
          }
        }
      })

      ws.onmessage = (event) => {
        const data: ISessionResponseData = JSON.parse(event.data)
        setData(data)
        if (data.nextStep === null) {
          ws.close()
        }
      }
    }

    ws.onclose = () => {
      setReady(false)
    }
  }, [])

  return { send, data, ready }
}

export default useWebSocket
