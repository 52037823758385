import { useState, useContext, useEffect } from 'react'
import { IProps, mergePropsWithDefault } from './select-template.models'
import './select-template.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown } from '../../componentsKit/dropdown/Dropdown'
import { DataList } from '../../componentsKit/data-list/DataList'
import { IData } from '../component.models'
import { Button } from '../../componentsKit/button/Button'
import { MapAlgo } from '../map-algo/MapAlgo'
import { ChampionAlgo } from '../champion-algo/ChampionAlgo'
import { championImage } from '../../images/champions/champions'
import { mapImage } from '../../images/maps/maps'
import { ModalContext } from '../../componentsKit/modal/ModalContext'
import { openIn } from '../../componentsKit/select/select.models'
import { Modal } from '../../componentsKit/modal/Modal'

export function SelectTemplate(props: IProps) {
  // props
  props = mergePropsWithDefault(props)
  // context
  const { open: openModal, close: closeModal } = useContext(ModalContext)
  // hooks
  const [opened, setOpened] = useState(false)
  useEffect(() => {
    if (props.openIn === openIn.Modal) {
      opened ? openModal() : closeModal()
    }
  }, [opened])

  // methods
  const open = () => {
    setOpened(true)
    props.onOpen ? props.onOpen() : void 0
  }
  const close = () => {
    setOpened(false)
    props.onClose ? props.onClose() : void 0
  }
  const toggle = () => {
    opened ? close() : open()
  }
  // handlers
  const selectedTemplateChangeHandler = (data: IData[]) => {
    props.template.onSelectedDataChange(data)
  }
  const selectedTemplateOptionChangeHandler = (data: IData[]) => {
    props.templateOption.onSelectedDataChange(data)
  }
  const openedChangeHandler = (opened: boolean) => {
    setOpened(opened)
  }
  // methods
  const clear = () => {
    close()
    setTimeout(() => {
      props.templateOption.onSelectedDataChange([])
      props.template.onSelectedDataChange([])
    }, 200)
  }
  // classes
  const selectClasses: string[] = [
    'qc-select-template',
    opened ? 'qc-select-template-opened' : '',
    props.template.selectedData.length === 0 ? 'qc-select-template-empty' : '',
    !props.caption ? 'qc-select-template-no-caption' : '',
    props.color ? `qc-select-template-${props.color}` : '',
    props.classes || '',
  ].filter((x) => x !== '')

  return (
    <div className={selectClasses.join(' ')} style={props.css}>
      {props.openIn === openIn.Dropdown && (
        <Dropdown
          {...props.dropdown}
          opened={opened}
          onOpenedChange={openedChangeHandler}
        >
          <div className="qc-select-template-dropdown">
            <div className="d-flex">
              <div className="d-flex flex-column">
                <div className="qc-select-template__template">
                  <span className="qc-select-template__template-title">
                    Template
                  </span>
                  <DataList
                    {...props.template}
                    color={props.color}
                    onSelectedDataChange={selectedTemplateChangeHandler}
                  />
                </div>
                {props.template.selectedData.length > 0 && (
                  <>
                    <div className="qc-select-template__template-option">
                      <span className="qc-select-template__template-title">
                        Option
                      </span>
                      <DataList
                        {...props.templateOption}
                        color={props.color}
                        onSelectedDataChange={
                          selectedTemplateOptionChangeHandler
                        }
                      />
                    </div>
                  </>
                )}
                {props.template.selectedData.length > 0 && (
                  <div className="d-flex justify-content-center mt-1 mb-2">
                    <Button
                      color="red"
                      text="Do not use template"
                      css={{ width: 220, height: 40 }}
                      onClick={clear}
                    />
                  </div>
                )}
              </div>
              {props.template.selectedData.length > 0 &&
                props.templateOption.selectedData.length > 0 && (
                  <>
                    <div className="qc-select-template__split"></div>
                    <div className="qc-select-template__preview">
                      <span className="qc-select-template__preview-title">
                        Preview
                      </span>
                      <div className="qc-select-template__preview-maps-champions-methods">
                        <div className="qc-select-template__preview-maps-and-champions">
                          <div className="qc-select-template__preview-game-type">
                            <div className="qc-select-template__preview-desc">
                              Game type
                            </div>
                            <div className="qc-select-template__preview-game-type-info">
                              <div className="qc-select-template__preview-info">
                                {
                                  props.templateOption.selectedData[0].gameType
                                    .name
                                }
                              </div>
                              <div className="qc-select-template__preview-info ml-1">
                                {
                                  props.templateOption.selectedData[0].bestOf
                                    .name
                                }
                              </div>
                            </div>
                          </div>
                          <div className="qc-select-template__preview-maps">
                            <div className="qc-select-template__preview-desc">
                              Maps
                            </div>
                            <div className="qc-select-template__preview-maps-list">
                              {props.templateOption.selectedData[0].maps.map(
                                (x: number) => {
                                  const findMap = props.maps.find(
                                    (y) => y.id === x
                                  )
                                  if (findMap) {
                                    const img = new Image()
                                    img.src = mapImage[findMap.name]
                                    img.alt = ''
                                    return (
                                      <img
                                        className="qc-select-template__preview-image"
                                        src={img.src}
                                        alt={img.alt}
                                      />
                                    )
                                  }
                                }
                              )}
                            </div>
                          </div>
                          <div className="qc-select-template__preview-champions">
                            <div className="qc-select-template__preview-desc">
                              Champions
                            </div>
                            <div className="qc-select-template__preview-champions-list">
                              {props.templateOption.selectedData[0].champions.map(
                                (x: number) => {
                                  const findChampion = props.champions.find(
                                    (y) => y.id === x
                                  )
                                  if (findChampion) {
                                    const img = new Image()
                                    img.src = championImage[findChampion.name]
                                    img.alt = ''
                                    return (
                                      <img
                                        className="qc-select-template__preview-image"
                                        src={img.src}
                                        alt={img.alt}
                                      />
                                    )
                                  }
                                }
                              )}
                            </div>
                          </div>
                          <div className="qc-select-template__preview-first">
                            <div className="qc-select-template__preview-desc">
                              First for map
                            </div>
                            <div className="qc-select-template__preview-first-info">
                              <div className="qc-select-template__preview-info">
                                {
                                  props.templateOption.selectedData[0].firstMap
                                    .name
                                }
                              </div>
                            </div>
                          </div>
                          <div className="qc-select-template__preview-first">
                            <div className="qc-select-template__preview-desc">
                              First for champion
                            </div>
                            <div className="qc-select-template__preview-first-info">
                              <div className="qc-select-template__preview-info">
                                {
                                  props.templateOption.selectedData[0]
                                    .firstChampion.name
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="qc-select-template__preview-methods-maps-and-champions">
                          <div className="qc-select-template__preview-methods-maps">
                            <div className="qc-select-template__preview-desc">
                              Pick and ban maps
                            </div>
                            <div className="qc-select-template__preview-methods-maps-info">
                              {props.template.selectedData.length > 0 &&
                                props.templateOption.selectedData.length >
                                  0 && (
                                  <MapAlgo
                                    mapAlgo={
                                      props.templateOption.selectedData[0]
                                        .map_algoID
                                    }
                                    mapCount={
                                      props.templateOption.selectedData[0].maps
                                        .length
                                    }
                                    bestOf={
                                      props.templateOption.selectedData[0]
                                        .bestOf.map_count
                                    }
                                    gameType={
                                      props.templateOption.selectedData[0]
                                        .game_typeID
                                    }
                                    firstMap={
                                      props.templateOption.selectedData[0]
                                        .first_mapID
                                    }
                                  />
                                )}
                            </div>
                          </div>
                          <div className="qc-select-template__preview-methods-champions">
                            <div className="qc-select-template__preview-desc">
                              Pick and ban champions
                            </div>
                            <div className="qc-select-template__preview-methods-champions-info">
                              {props.template.selectedData.length > 0 &&
                                props.templateOption.selectedData.length >
                                  0 && (
                                  <ChampionAlgo
                                    championAlgo={
                                      props.templateOption.selectedData[0]
                                        .champion_algoID
                                    }
                                    bestOf={
                                      props.templateOption.selectedData[0]
                                        .bestOf.map_count
                                    }
                                    gameType={
                                      props.templateOption.selectedData[0]
                                        .game_typeID
                                    }
                                    firstMapID={
                                      props.templateOption.selectedData[0]
                                        .first_mapID
                                    }
                                    firstChampionID={
                                      props.templateOption.selectedData[0]
                                        .first_championID
                                    }
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </div>
        </Dropdown>
      )}
      {props.openIn === openIn.Modal && opened && (
        <Modal onClose={close}>
          <div className="qc-select-template-dropdown">
            <div className="d-flex">
              <div className="d-flex flex-column">
                <div className="qc-select-template__template">
                  <span className="qc-select-template__template-title">
                    Template
                  </span>
                  <DataList
                    {...props.template}
                    color={props.color}
                    onSelectedDataChange={selectedTemplateChangeHandler}
                  />
                </div>
                {props.template.selectedData.length > 0 && (
                  <>
                    <div className="qc-select-template__template-option">
                      <span className="qc-select-template__template-title">
                        Option
                      </span>
                      <DataList
                        {...props.templateOption}
                        color={props.color}
                        onSelectedDataChange={
                          selectedTemplateOptionChangeHandler
                        }
                      />
                    </div>
                  </>
                )}
                {props.template.selectedData.length > 0 && (
                  <div className="d-flex justify-content-center mt-1 mb-2">
                    <Button
                      color="red"
                      text="Do not use template"
                      css={{ width: 220, height: 40 }}
                      onClick={clear}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
      <div className="qc-select-template__input" onClick={toggle}>
        <div className="qc-select-template__input-container">
          {props.caption && (
            <label className="qc-select-template__caption">
              {props.caption}
            </label>
          )}
          <label className="qc-select-template__value">
            {props.template.selectedData.length > 0
              ? `${props.template.selectedData[0][props.template.valueField!]}`
              : ''}
          </label>
        </div>
        <div className="qc-select-template__icon">
          <FontAwesomeIcon
            icon={props.arrowIcon!}
            className="qc-select-template__arrow"
          />
        </div>
      </div>
      {props.openIn !== openIn.Modal && (
        <span className="qc-select__bar"></span>
      )}
    </div>
  )
}
