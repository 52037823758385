import { CSSProperties } from 'react'
import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

export enum QCButtonTypes {
  Default = 'default',
  Outline = 'outline',
  Icon = 'icon',
  Round = 'round',
}

export interface IProps {
  text?: string
  classes?: string
  css?: CSSProperties
  type?: QCButtonTypes
  disabled?: boolean
  disabledWithoutChangeStyles?: boolean
  iconLeft?: IconDefinition
  iconRight?: IconDefinition
  color?: string
  //
  onClick?: () => void
}

export const defaultProps: IProps = {
  disabled: false,
  disabledWithoutChangeStyles: false,
  type: QCButtonTypes.Default,
}

export function mergePropsWithDefault(
  props: IProps,
  defaults: IProps = defaultProps
): IProps {
  const cssProps = { ...defaults.css, ...props.css }
  return {
    ...defaults,
    ...props,
    css: cssProps,
  } as IProps
}
