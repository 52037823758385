import { IProps, mergePropsWithDefault } from './radio.models'
import './radio.scss'
import { RadioItem } from './RadioItem'

export function Radio(props: IProps) {
  // props
  props = mergePropsWithDefault(props)
  // classes
  const checkboxClasses: string[] = [
    'qc-radio-container',
    props.classes || '',
  ].filter((x) => x !== '')
  //

  return (
    <div className={checkboxClasses.join(' ')}>
      <div className="qc-radio__caption">{props.caption}</div>
      <div className="d-flex flex-column">
        {props.data!.map((item) => {
          return (
            <RadioItem
              dataItem={item}
              keyField={props.keyField}
              valueField={props.valueField}
              checked={
                props.checkedItem !== null &&
                props.checkedItem[props.keyField!] === item[props.keyField!]
              }
              onCheckedItemChange={props.onCheckedItemChange}
              disabled={
                props.itemProps![item[props.keyField!]] &&
                props.itemProps![item[props.keyField!]].disabled !== undefined
                  ? props.itemProps![item[props.keyField!]].disabled
                  : props.disabled
              }
              classes={
                (props.itemProps![item[props.keyField!]] &&
                  props.itemProps![item[props.keyField!]].classes) ||
                ''
              }
              css={
                (props.itemProps![item[props.keyField!]] &&
                  props.itemProps![item[props.keyField!]].css) ||
                {}
              }
            />
          )
        })}
      </div>
    </div>
  )
}
