export enum ValidationStart {
  onChange,
  onBlur,
  onSubmit,
}

export interface IValidation {
  checks: IValidationCheck[]
  canValidate: boolean
  setCanValidate: (can: boolean) => void
  validationStart?: ValidationStart
}

interface IValidationCheck {
  isValid: boolean
  error: string
}

export const defaultValidationProps = {
  checks: [],
  canValidate: false,
  setCanValdiate: () => void 0,
  validationStart: ValidationStart.onBlur,
}
