import { CSSTransition } from 'react-transition-group'
import { IProps, mergePropsWithDefault } from './dropdown.models'
import './dropdown.scss'

export function Dropdown(props: IProps) {
  // props
  props = mergePropsWithDefault(props)
  // classes
  const dropdownClasses: string[] = [
    'qc-dropdown',
    props.opened ? `qc-dropdown-open` : '',
  ].filter((x) => x !== '')
  const dropdownContainerClasses: string[] = [
    'qc-dropdown-container',
    `qc-dropdown-container-from-${props.from}`,
    props.disableOpenAnimation ? 'disable-open-animation' : '',
    props.disableCloseAnimation ? 'disable-close-animation' : '',
  ].filter((x) => x !== '')
  // methods
  const close = () => {
    props.onOpenedChange(false)
  }

  return (
    <div className={dropdownClasses.join(' ')}>
      {props.opened && !props.disableBackdrop && (
        <div className="qc-dropdown__backdrop" onClick={close}></div>
      )}
      <CSSTransition
        in={props.opened}
        timeout={
          props.css && props.css['--transition-delay-ms']
            ? +props.css['--transition-delay-ms'].slice(
                0,
                props.css['--transition-delay-ms'].length - 2
              )
            : 200
        }
        classNames={`qc-dropdown-container-from-${props.from}`}
        unmountOnExit
      >
        <div className={dropdownContainerClasses.join(' ')} style={props.css}>
          {props.children}
        </div>
      </CSSTransition>
    </div>
  )
}
