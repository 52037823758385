import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { ModalState } from './componentsKit/modal/ModalContext'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import { store } from './store'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <ModalState>
    <App />
  </ModalState>
)

reportWebVitals()
