import { useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { IData } from '../components/component.models'
import { IProps as ISelectProps, openIn } from '../componentsKit/select/select.models'
import { Select } from '../componentsKit/select/Select'
import '../scss/qc.scss'
import '../scss/create-session.scss'
import { IProps as ITextEditProps } from '../componentsKit/text-edit/text-edit.models'
import { TextEdit } from '../componentsKit/text-edit/TextEdit'
import { DataListTypes } from '../componentsKit/data-list/data-list.models'
import { ICSSProps } from '../componentsKit/dropdown/dropdown.models'
import { Button } from '../componentsKit/button/Button'
import { useHttp } from '../hooks/http.hook'
import { ICreateSession } from '../models/session.model'
import { ValidationStart } from '../validator/validation.models'
import { Validation } from '../validator/validation'
import { IProps as ISelectGameTypeFull } from '../components/select-gametype-full/select-gametype-full.models'
import { IBestOfChampionAlgo, IBestOfMapAlgo, useDataForCreateSession } from '../hooks/dataForCreateSession.hook'
import { Alert } from '../componentsKit/alert/Alert'
import { CSSTransition } from 'react-transition-group'
import { IProps as ISelectTemplateProps } from '../components/select-template/select-template.models'
import { SelectTemplate } from '../components/select-template/SelectTemplate'
import { Loader } from '../componentsKit/loader/Loader'
import { AuthContext } from '../context/AuthContext'
import { SelectGameTypeFull } from '../components/select-gametype-full/SelectGameTypeFull'

export function CreateSessionPage() {
  //
  const openInModalWidth = 1100
  // context
  const { token } = useContext(AuthContext)
  // hooks
  const navigate = useNavigate()
  const { request } = useHttp()
  // data from server
  const { data, loading, error } = useDataForCreateSession()
  const [dataReady, setDataReady] = useState(false)
  //
  const [templateSelectedData, setTemplateSelectedData] = useState<IData[]>([])
  const [templateOptionSelectedData, setTemplateOptionSelectedData] = useState<IData[]>([])
  const [templateSelectOpened, setTemplateSelectOpened] = useState(false)
  const [gameTypeSelectedData, setGameTypeSelectedData] = useState<IData[]>([])
  const [mapAlgoSelectedData, setMapAlgoSelectedData] = useState<IData[]>([])
  const [championAlgoSelectedData, setChampionAlgoSelectedData] = useState<IData[]>([])
  const [bestOfSelectedData, setBestOfSelectedData] = useState<IData[]>([])
  const [gameTypeSelectOpened, setGameTypeSelectOpened] = useState(false)
  const [championSelectedData, setChampionSelectedData] = useState<IData[]>([])
  const [championSelectOpened, setChampionSelectOpened] = useState(false)
  const [mapSelectedData, setMapSelectedData] = useState<IData[]>([])
  const [mapSelectOpened, setMapSelectOpened] = useState(false)
  const [player1EditValue, setPlayer1EditValue] = useState('')
  const [player2EditValue, setPlayer2EditValue] = useState('')
  const [firstMapSelectedData, setFirstMapSelectedData] = useState<IData[]>([])
  const [firstChampionSelectedData, setFirstChampionSelectedData] = useState<IData[]>([])
  //
  const [playerOrTeam, setPlayerOrTeam] = useState('Player')
  const [minMaps, setMinMaps] = useState(0)
  const [minChampions, setMinChampions] = useState(0)
  //
  useEffect(() => {
    if (!loading && !error && data) {
      setChampionSelectedData(getDefaultChampionPool(data.champion))
      setMapSelectedData(getDefaultMapPool(data.map))
      const defaultGameType = getDefaultGameType(data.gameType)
      setGameTypeSelectedData(defaultGameType)
      const defaultBestOf = getDefaultBestOf(data.bestOf)
      setBestOfSelectedData(defaultBestOf)
      setMapAlgoSelectedData(getDefaultMapAlgo(data.bestOfMapAlgo, defaultGameType[0].id, defaultBestOf[0].id))
      setChampionAlgoSelectedData(
        getDefaultChampionAlgo(data.bestOfChampionAlgo, defaultGameType[0].id, defaultBestOf[0].id)
      )
      setFirstMapSelectedData(getDefaultMapFirst(data.first))
      setFirstChampionSelectedData(getDefaultChampionFirst(data.first))
      setDataReady(true)
    }
  }, [data])
  useEffect(() => {
    if (
      !loading &&
      !error &&
      data &&
      bestOfSelectedData.length > 0 &&
      gameTypeSelectedData.length > 0 &&
      mapAlgoSelectedData.length > 0
    ) {
      const findMinMaps = data.bestOfMapAlgo.find(
        (x) =>
          x.best_ofID === bestOfSelectedData[0].id &&
          x.game_typeID === gameTypeSelectedData[0].id &&
          x.map_algoID === mapAlgoSelectedData[0].map_algoID
      )
      if (findMinMaps) {
        setCanValidateMaps(true)
        setMinMaps(findMinMaps.min_maps)
      }
    }
  }, [bestOfSelectedData, gameTypeSelectedData, mapAlgoSelectedData])
  useEffect(() => {
    if (
      !loading &&
      !error &&
      data &&
      bestOfSelectedData.length > 0 &&
      gameTypeSelectedData.length > 0 &&
      championAlgoSelectedData.length > 0
    ) {
      const findMinChampions = data.bestOfChampionAlgo.find(
        (x) =>
          x.best_ofID === bestOfSelectedData[0].id &&
          x.game_typeID === gameTypeSelectedData[0].id &&
          x.champion_algoID === championAlgoSelectedData[0].champion_algoID
      )
      if (findMinChampions) {
        setCanValidateChampions(true)
        setMinChampions(findMinChampions.min_champions)
      }
    }
  }, [bestOfSelectedData, gameTypeSelectedData, championAlgoSelectedData])
  useEffect(() => {
    if (
      !loading &&
      !error &&
      data &&
      bestOfSelectedData.length > 0 &&
      gameTypeSelectedData.length > 0 &&
      templateSelectedData.length === 0
    ) {
      const findMapAlgo: IBestOfMapAlgo | undefined = data.bestOfMapAlgo.find(
        (x) =>
          x.best_ofID === bestOfSelectedData[0].id &&
          x.game_typeID === gameTypeSelectedData[0].id &&
          x.is_default === true
      )
      if (findMapAlgo) {
        setMapAlgoSelectedData([findMapAlgo])
      }
      //
      const findChampionAlgo: IBestOfChampionAlgo | undefined = data.bestOfChampionAlgo.find(
        (x) =>
          x.best_ofID === bestOfSelectedData[0].id &&
          x.game_typeID === gameTypeSelectedData[0].id &&
          x.is_default === true
      )
      if (findChampionAlgo) {
        setChampionAlgoSelectedData([findChampionAlgo])
      }
    }
  }, [bestOfSelectedData, gameTypeSelectedData])
  useEffect(() => {
    if (templateOptionSelectedData.length > 0) {
      setFirstMapSelectedData(data!.first.filter((x) => x.id === templateOptionSelectedData[0].first_mapID))
      setFirstChampionSelectedData(data!.first.filter((x) => x.id === templateOptionSelectedData[0].first_championID))
      setMapSelectedData(data!.map.filter((x) => templateOptionSelectedData[0].maps.includes(x.id)))
      setChampionSelectedData(data!.champion.filter((x) => templateOptionSelectedData[0].champions.includes(x.id)))
      setBestOfSelectedData(data!.bestOf.filter((x) => x.id === templateOptionSelectedData[0].best_ofID))
      setGameTypeSelectedData(data!.gameType.filter((x) => x.id === templateOptionSelectedData[0].game_typeID))
      setMapAlgoSelectedData(
        data!.bestOfMapAlgo.filter(
          (x) =>
            x.map_algoID === templateOptionSelectedData[0].map_algoID &&
            x.best_ofID === templateOptionSelectedData[0].best_ofID &&
            x.game_typeID === templateOptionSelectedData[0].game_typeID
        )
      )
      setChampionAlgoSelectedData(
        data!.bestOfChampionAlgo.filter(
          (x) =>
            x.champion_algoID === templateOptionSelectedData[0].champion_algoID &&
            x.best_ofID === templateOptionSelectedData[0].best_ofID &&
            x.game_typeID === templateOptionSelectedData[0].game_typeID
        )
      )
    }
  }, [templateOptionSelectedData])
  //
  const [canValidatePlayer1, setCanValidatePlayer1] = useState(false)
  const [canValidatePlayer2, setCanValidatePlayer2] = useState(false)
  const [canValidateMaps, setCanValidateMaps] = useState(false)
  const [canValidateChampions, setCanValidateChampions] = useState(false)
  const [buttonDisabled, setButtonDisabled] = useState(false)
  // default
  const getDefaultMapPool = (maps: IData[]) => maps.filter((x) => x.is_default === true)
  const getDefaultChampionPool = (champions: IData[]) => champions.filter((x) => x.is_default === true)
  const getDefaultGameType = (gameTypes: IData[]) => gameTypes.filter((x) => x.is_default === true)
  const getDefaultBestOf = (bestOf: IData[]) => bestOf.filter((x) => x.is_default === true)
  const getDefaultMapAlgo = (mapAlgo: IData[], defaultGameTypeID: number, defaultBestOfID: number) =>
    mapAlgo.filter(
      (x) => x.game_typeID === defaultGameTypeID && x.best_ofID === defaultBestOfID && x.is_default === true
    )
  const getDefaultChampionAlgo = (championAlgo: IData[], defaultGameTypeID: number, defaultBestOfID: number) =>
    championAlgo.filter(
      (x) => x.game_typeID === defaultGameTypeID && x.best_ofID === defaultBestOfID && x.is_default === true
    )
  const getDefaultMapFirst = (first: IData[]) => first.filter((x) => x.type === 'map' && x.is_default === true)
  const getDefaultChampionFirst = (first: IData[]) =>
    first.filter((x) => x.type === 'champion' && x.is_default === true)
  // handlers
  const selectTemplateHandler = (selectedData: IData[]) => {
    setTemplateSelectedData(selectedData)
    //
    if (selectedData.length > 0) {
      const findTemplateOption = data!.templateOption.find(
        (x) => x.templateID === selectedData[0].id && x.is_default === true
      )
      if (findTemplateOption) {
        setTemplateOptionSelectedData([findTemplateOption])
      }
    } else {
      setChampionSelectedData(getDefaultChampionPool(data!.champion))
      setMapSelectedData(getDefaultMapPool(data!.map))
      const defaultGameType = getDefaultGameType(data!.gameType)
      setGameTypeSelectedData(defaultGameType)
      const defaultBestOf = getDefaultBestOf(data!.bestOf)
      setBestOfSelectedData(defaultBestOf)
      setMapAlgoSelectedData(getDefaultMapAlgo(data!.bestOfMapAlgo, defaultGameType[0].id, defaultBestOf[0].id))
      setChampionAlgoSelectedData(
        getDefaultChampionAlgo(data!.bestOfChampionAlgo, defaultGameType[0].id, defaultBestOf[0].id)
      )
      setFirstMapSelectedData(getDefaultMapFirst(data!.first))
      setFirstChampionSelectedData(getDefaultChampionFirst(data!.first))
    }
  }
  const selectTemplateOptionHandler = (selectedData: IData[]) => {
    setMapAlgoSelectedData([])
    setChampionAlgoSelectedData([])
    setTemplateOptionSelectedData(selectedData)
  }
  const selectGameTypeHandler = (data: IData[]) => {
    setMapAlgoSelectedData([])
    setChampionAlgoSelectedData([])
    setGameTypeSelectedData(data)
    data[0].name.includes('Team') ? setPlayerOrTeam('Team') : setPlayerOrTeam('Player')
  }
  const selectBestOfHandler = (data: IData[]) => {
    setMapAlgoSelectedData([])
    setChampionAlgoSelectedData([])
    setBestOfSelectedData(data)
  }
  const selectMapAlgoHandler = (data: IData[]) => {
    setMapAlgoSelectedData(data)
  }
  const selectChampionAlgoHandler = (data: IData[]) => {
    setChampionAlgoSelectedData(data)
  }
  const selectChampionHandler = (data: IData[]) => {
    setChampionSelectedData(data)
  }
  const selectMapHandler = (data: IData[]) => {
    setMapSelectedData(data)
  }
  const regExp = new RegExp(/^[\w0-9 ]+$/, 'g')
  const changePlayer1Handler = (value: string) => {
    if (value.length === 0 || regExp.test(value)) {
      setPlayer1EditValue(value)
    }
  }
  const changePlayer2Handler = (value: string) => {
    if (value.length === 0 || regExp.test(value)) {
      setPlayer2EditValue(value)
    }
  }
  const selectFirstMapHandler = (data: IData[]) => {
    setFirstMapSelectedData(data)
  }
  const selectFirstChampionHandler = (data: IData[]) => {
    setFirstChampionSelectedData(data)
  }
  const createSessionHandler = async (simulate: boolean = false) => {
    if (!canValidatePlayer1) {
      setCanValidatePlayer1(true)
    }
    if (!canValidatePlayer2) {
      setCanValidatePlayer2(true)
    }
    if (!canValidateMaps) {
      setCanValidateMaps(true)
    }
    if (!canValidateChampions) {
      setCanValidateChampions(true)
    }
    if (
      textEditPlayer1Props.isValid &&
      textEditPlayer2Props.isValid &&
      selectMapsProps.dataList.isValid &&
      selectChampionProps.dataList.isValid
    ) {
      setButtonDisabled(true)
      try {
        const session: ICreateSession = {
          player1: player1EditValue,
          player2: player2EditValue,
          maps: mapSelectedData.map((map) => map.id),
          champions: championSelectedData.map((map) => map.id),
          firstMap: firstMapSelectedData[0].id,
          firstChampion: firstChampionSelectedData[0].id,
          gameType: gameTypeSelectedData[0].id,
          bestOf: bestOfSelectedData[0].id,
          mapAlgo: mapAlgoSelectedData[0].map_algoID,
          championAlgo: championAlgoSelectedData[0].champion_algoID,
          template: templateSelectedData.length > 0 ? templateSelectedData[0].id : null,
          templateName: templateSelectedData.length > 0 ? templateSelectedData[0].name : null,
          templateOptionName: templateOptionSelectedData.length > 0 ? templateOptionSelectedData[0].name : null,
          templateBracketLink: templateSelectedData.length > 0 ? templateSelectedData[0].bracket_link : null,
          simulate,
        }

        const data = await request('/session/create', 'POST', session, {
          Authorization: token ? `Bearer ${token}` : '',
        })
        if (data.error) {
          setButtonDisabled(false)
          console.error(`${data.error}: ${data.message}`)
        } else {
          navigate(`/session/${data.playerCode}/${data.player1}`)
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
  // props
  const dropdownCssProps: ICSSProps = {
    '--left': '290px',
    '--top': '0',
  }

  const selectTemplateProps: ISelectTemplateProps = {
    template: {
      data: data?.template || [],
      dataLoading: loading,
      dataLoadError: error,
      selectedData: templateSelectedData,
      onSelectedDataChange: selectTemplateHandler,
      valueField: 'name',
    },
    templateOption: {
      data:
        data && templateSelectedData.length > 0
          ? data.templateOption.filter((x) => x.templateID === templateSelectedData[0].id)
          : [],
      dataLoading: loading,
      dataLoadError: error,
      selectedData: templateOptionSelectedData,
      onSelectedDataChange: selectTemplateOptionHandler,
      valueField: 'name',
    },
    dropdown: {
      opened: templateSelectOpened,
      onOpenedChange: setTemplateSelectOpened,
      css: dropdownCssProps,
    },
    caption: 'Use template',
    maps: data?.map || [],
    champions: data?.champion || [],
    openIn: window.innerWidth >= openInModalWidth ? openIn.Dropdown : openIn.Modal,
  }

  const selectGameTypeFullProps: ISelectGameTypeFull = {
    gameType: {
      data: data?.gameType || [],
      dataLoading: loading,
      dataLoadError: error,
      selectedData: gameTypeSelectedData,
      onSelectedDataChange: selectGameTypeHandler,
      valueField: 'name',
    },
    bestOf: {
      data: data?.bestOf || [],
      dataLoading: loading,
      dataLoadError: error,
      selectedData: bestOfSelectedData,
      onSelectedDataChange: selectBestOfHandler,
      valueField: 'name',
    },
    mapAlgo: {
      data:
        !loading && !error && data && bestOfSelectedData.length > 0 && gameTypeSelectedData.length > 0
          ? data.bestOfMapAlgo.filter(
              (x) => x.best_ofID === bestOfSelectedData[0].id && x.game_typeID === gameTypeSelectedData[0].id
            )
          : [],
      dataLoading: loading,
      dataLoadError: error,
      selectedData: mapAlgoSelectedData,
      onSelectedDataChange: selectMapAlgoHandler,
      keyField: 'map_algoID',
      valueField: 'name',
    },
    championAlgo: {
      data:
        !loading && !error && data && bestOfSelectedData.length > 0 && gameTypeSelectedData.length > 0
          ? data!.bestOfChampionAlgo.filter(
              (x) => x.best_ofID === bestOfSelectedData[0].id && x.game_typeID === gameTypeSelectedData[0].id
            )
          : [],
      dataLoading: loading,
      dataLoadError: error,
      selectedData: championAlgoSelectedData,
      onSelectedDataChange: selectChampionAlgoHandler,
      keyField: 'champion_algoID',
      valueField: 'name',
    },
    firstMap: {
      data: data?.first.filter((x) => x.type === 'map') || [],
      dataLoading: loading,
      dataLoadError: error,
      selectedData: firstMapSelectedData,
      onSelectedDataChange: selectFirstMapHandler,
      valueField: 'name',
    },
    firstChampion: {
      data: data?.first.filter((x) => x.type === 'champion') || [],
      dataLoading: loading,
      dataLoadError: error,
      selectedData: firstChampionSelectedData,
      onSelectedDataChange: selectFirstChampionHandler,
      valueField: 'name',
    },
    dropdown: {
      opened: gameTypeSelectOpened,
      onOpenedChange: setGameTypeSelectOpened,
      css: {
        '--left': '290px',
        '--top': '-76px',
      },
    },
    caption: 'Game type',
    mapCount: mapSelectedData.length,
    championCount: championSelectedData.length,
    bestOfMapAlgo: data?.bestOfMapAlgo || [],
    bestOfChampionAlgo: data?.bestOfChampionAlgo || [],
    selectedBestOf: bestOfSelectedData,
    selectedGameType: gameTypeSelectedData,
    openIn: window.innerWidth >= openInModalWidth ? openIn.Dropdown : openIn.Modal,
  }

  const championsValidation = new Validation({
    checks: [
      {
        isValid:
          gameTypeSelectedData.length > 0 && bestOfSelectedData.length > 0
            ? championSelectedData.length >= minChampions
            : false,
        error:
          gameTypeSelectedData.length > 0 && bestOfSelectedData.length > 0
            ? `${gameTypeSelectedData[0].name}  ${bestOfSelectedData[0].name} require at least ${minChampions} champions`
            : '',
      },
    ],
    canValidate: canValidateChampions,
    setCanValidate: setCanValidateChampions,
    validationStart: ValidationStart.onChange,
  })
  const selectChampionProps: ISelectProps = {
    dataList: {
      data: data?.champion || [],
      dataLoading: loading,
      dataLoadError: error,
      valueField: 'name',
      selectedData: championSelectedData,
      onSelectedDataChange: selectChampionHandler,
      showSelected: true,
      multiselect: true,
      type: DataListTypes.Grid,
      columnCount: 2,
      validation: championsValidation.props,
      isValid: championsValidation.isValid,
      errors: championsValidation.errors,
    },
    dropdown: {
      opened: championSelectOpened,
      onOpenedChange: setChampionSelectOpened,
      css: {
        '--left': '290px',
        '--top': '-228px',
      },
    },
    modal: {
      children: '',
      cssWindow: {
        backgroundColor: 'transparent',
      },
    },
    caption: 'Champion pool',
    for: 'champions',
    openIn: window.innerWidth >= openInModalWidth ? openIn.Dropdown : openIn.Modal,
  }

  const mapsValidation = new Validation({
    checks: [
      {
        isValid:
          gameTypeSelectedData.length > 0 && bestOfSelectedData.length > 0 ? mapSelectedData.length >= minMaps : false,
        error:
          gameTypeSelectedData.length > 0 && bestOfSelectedData.length > 0
            ? `${gameTypeSelectedData[0].name} ${bestOfSelectedData[0].name} require at least ${minMaps} maps`
            : '',
      },
    ],
    canValidate: canValidateMaps,
    setCanValidate: setCanValidateMaps,
    validationStart: ValidationStart.onChange,
  })
  const selectMapsProps: ISelectProps = {
    dataList: {
      data: data?.map || [],
      dataLoadError: error,
      dataLoading: loading,
      valueField: 'name',
      selectedData: mapSelectedData,
      onSelectedDataChange: selectMapHandler,
      showSelected: true,
      multiselect: true,
      type: DataListTypes.Grid,
      validation: mapsValidation.props,
      isValid: mapsValidation.isValid,
      errors: mapsValidation.errors,
      columnCount: 3,
    },
    dropdown: {
      opened: mapSelectOpened,
      onOpenedChange: setMapSelectOpened,
      css: {
        '--left': '290px',
        '--top': '-152px',
      },
    },
    modal: {
      children: '',
      cssWindow: {
        backgroundColor: 'transparent',
      },
    },
    caption: 'Map pool',
    for: 'maps',
    openIn: window.innerWidth >= openInModalWidth ? openIn.Dropdown : openIn.Modal,
  }

  const player1Validation = new Validation({
    checks: [
      {
        isValid: player1EditValue.trim().length > 0,
        error: playerOrTeam === 'Player' ? 'Please enter player 1 nickname' : 'Please enter team 1 name',
      },
      {
        isValid: player1EditValue.trim() !== player2EditValue.trim(),
        error: 'Player names cannot match',
      },
    ],
    canValidate: canValidatePlayer1,
    setCanValidate: setCanValidatePlayer1,
  })
  const textEditPlayer1Props: ITextEditProps = {
    editValue: player1EditValue,
    onEditValueChange: changePlayer1Handler,
    caption: `${playerOrTeam} 1`,
    maxLength: 16,
    validation: player1Validation.props,
    errors: player1Validation.errors,
    isValid: player1Validation.isValid,
  }

  const player2Validation = new Validation({
    checks: [
      {
        isValid: player2EditValue.trim().length > 0,
        error: playerOrTeam === 'Player' ? 'Please enter player 2 nickname' : 'Please enter team 2 name',
      },
      {
        isValid: player1EditValue.trim() !== player2EditValue.trim(),
        error: 'Player names cannot match',
      },
    ],
    canValidate: canValidatePlayer2,
    setCanValidate: setCanValidatePlayer2,
  })
  const textEditPlayer2Props: ITextEditProps = {
    editValue: player2EditValue,
    onEditValueChange: changePlayer2Handler,
    caption: `${playerOrTeam} 2`,
    maxLength: 16,
    validation: player2Validation.props,
    errors: player2Validation.errors,
    isValid: player2Validation.isValid,
  }
  //
  const getButtonDisabled = () => {
    return canValidatePlayer1 &&
      canValidatePlayer2 &&
      canValidateMaps &&
      canValidateChampions &&
      (!textEditPlayer1Props.isValid ||
        !textEditPlayer2Props.isValid ||
        !selectMapsProps.dataList.isValid ||
        !selectChampionProps.dataList.isValid)
      ? true
      : false
  }

  return (
    <div className="qc-container qc-create-session-container">
      <div className="qc-content">
        <>
          <div className="qc-content__header">
            <h1>
              Create session
              <CSSTransition in={loading} timeout={300} classNames={'qc-loader-container'} unmountOnExit>
                <div className="qc-loader-container qc-content__header-loader">
                  <Loader />
                </div>
              </CSSTransition>
            </h1>
          </div>
          {error ? (
            <Alert color="red" title="Error" description={error} />
          ) : (
            <CSSTransition in={dataReady} timeout={500} classNames={'use-css-transition'} unmountOnExit>
              <div className="use-css-transition qc-create-session mb-2">
                <SelectTemplate {...selectTemplateProps} />
                {templateSelectedData.length === 0 && (
                  <>
                    <SelectGameTypeFull {...selectGameTypeFullProps} classes="mt-2" />
                    <Select {...selectMapsProps} classes="mt-2" />
                    <Select {...selectChampionProps} classes="mt-2" />
                  </>
                )}

                <TextEdit {...textEditPlayer1Props} classes="mt-2" />
                <TextEdit {...textEditPlayer2Props} classes="mt-2" />
                <Button
                  text="Create game session"
                  css={{ width: 280 }}
                  classes="mt-2"
                  onClick={() => createSessionHandler(false)}
                  disabled={getButtonDisabled()}
                  disabledWithoutChangeStyles={buttonDisabled}
                  color="green"
                />
                <Button
                  text="Simulate game session"
                  css={{ width: 280 }}
                  classes="mt-2"
                  onClick={() => createSessionHandler(true)}
                  disabled={getButtonDisabled()}
                  disabledWithoutChangeStyles={buttonDisabled}
                  color="green"
                />
              </div>
            </CSSTransition>
          )}
        </>
      </div>
    </div>
  )
}
