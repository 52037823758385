import { useEffect, useState } from 'react'
import axios, { AxiosError } from 'axios'

interface IGameType {
  name: string
}

interface IBestOf {
  name: string
}

export interface ISession {
  player1: string
  player2: string
  spectator_code: string
  dt_create: string
  gameType: IGameType
  bestOf: IBestOf
  template_name: string | null
  template_option_name: string | null
  template_bracket_link: string | null
}

export function useSession() {
  const [sessions, setSessions] = useState<ISession[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  async function fetchMaps() {
    try {
      setError('')
      setLoading(true)
      const response = await axios.get<ISession[]>('/sessions/getSessions')

      setSessions(response.data)
      setLoading(false)
    } catch (e) {
      const error = e as AxiosError
      setLoading(false)
      setError(error.message || 'Fetch data error')
    }
  }

  useEffect(() => {
    fetchMaps()
  }, [])

  return { sessions, loading, error }
}
