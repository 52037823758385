import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight as ArrowIcon } from '@fortawesome/free-solid-svg-icons'
import { CSSProperties } from 'react'
import { IData } from '../../components/component.models'
import {
  DataListTypes,
  IProps as IDataListProps,
  mergePropsWithDefault as mergeDataListPropsWithDefault,
  defaultProps as dataListDefaults,
} from '../data-list/data-list.models'
import {
  IProps as IDropdownProps,
  mergePropsWithDefault as mergeDropdownPropsWithDefault,
} from '../dropdown/dropdown.models'
import {
  IProps as IModalProps,
  mergePropsWithDefault as mergeModalPropsWithDefault,
} from '../modal/modal.model'

export enum openIn {
  Dropdown = 'dropdown',
  Modal = 'modal',
}

export interface IProps {
  classes?: string
  css?: CSSProperties
  dataList: IDataListProps
  dropdown?: IDropdownProps
  modal?: IModalProps
  caption?: string
  arrowIcon?: IconDefinition
  color?: string
  for?: string
  openIn?: openIn
  //
  onOpen?: () => void
  onClose?: () => void
}

export const defaultProps: IProps = {
  dataList: {
    data: [] as IData[],
    selectedData: [] as IData[],
    onSelectedDataChange: (data: IData[]) => void 0,
    multiselect: false,
    type: DataListTypes.Default,
  },
  dropdown: {
    opened: false,
    onOpenedChange: () => void 0,
  },
  arrowIcon: ArrowIcon,
  color: 'green',
  openIn: openIn.Dropdown,
}

export function mergePropsWithDefault(
  props: IProps,
  defaults: IProps = defaultProps
): IProps {
  const cssProps = { ...defaults.css, ...props.css }
  if (props.css?.width) {
    if (!props.dataList.css) {
      props.dataList.css = {} as CSSProperties
    }
    props.dataList.css.width = props.css.width
  }
  let dataListProps = mergeDataListPropsWithDefault(
    defaults.dataList,
    dataListDefaults
  )
  dataListProps = mergeDataListPropsWithDefault(props.dataList, dataListProps)

  const dropdownProps = props.dropdown
    ? mergeDropdownPropsWithDefault(props.dropdown, defaults.dropdown)
    : defaults.dropdown

  return {
    ...defaults,
    ...props,
    dataList: dataListProps,
    dropdown: dropdownProps,
    css: cssProps,
  } as IProps
}
