import { useEffect, useState, useContext } from 'react'
import axios, { AxiosError } from 'axios'
import { AuthContext } from '../context/AuthContext'

export interface ITemplate {
  id: number
  name: string
  sort_param: number
  is_default: boolean
  firstID: number
  creatorUserID: number
}

export function useMyTemplates() {
  const [templates, setTemplates] = useState<ITemplate[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { token } = useContext(AuthContext)

  async function fetchTemplates() {
    try {
      setError('')
      setLoading(true)
      const response = await axios.get<ITemplate[]>(
        '/my-templates/getMyTemplates',
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )

      setTemplates(response.data)
      setLoading(false)
    } catch (e) {
      const error = e as AxiosError
      setLoading(false)
      setError(error.message || 'Fetch data error')
    }
  }

  useEffect(() => {
    fetchTemplates()
  }, [])

  return { templates, loading, error }
}
