import { CSSProperties } from 'react'

export interface IProps {
  classes?: string
  css?: CSSProperties
  mapAlgo: number
  mapCount: number
  bestOf: number
  gameType: number
  firstMap: number
}

export const defaultProps: IProps = {
  mapAlgo: 1,
  mapCount: 7,
  bestOf: 3,
  gameType: 9,
  firstMap: 1,
}

export function mergePropsWithDefault(
  props: IProps,
  defaults: IProps = defaultProps
): IProps {
  const cssProps = { ...defaults.css, ...props.css }
  return {
    ...defaults,
    ...props,
    css: cssProps,
  } as IProps
}
