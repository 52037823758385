import { CSSProperties } from 'react'

export interface IProps {
  classes?: string
  css?: CSSProperties
  caption?: string
  value?: string
  color?: string
  //
  onClick?: () => void
}

export const defaultProps: IProps = {
  color: 'green',
}

export function mergePropsWithDefault(
  props: IProps,
  defaults: IProps = defaultProps
): IProps {
  const cssProps = { ...defaults.css, ...props.css }

  return {
    ...defaults,
    ...props,
    css: cssProps,
  } as IProps
}
