import galena from './qc-galena.png'
import nyx from './qc-nyx.png'
import sorlag from './qc-sorlag.png'
import strogg from './qc-strogg.png'
import slash from './qc-slash.png'
import scalebearer from './qc-scalebearer.png'
import anarki from './qc-anarki.png'
import athena from './qc-athena.png'
import bj from './qc-bj.png'
import clutch from './qc-clutch.png'
import doom from './qc-doom.png'
import eisen from './qc-eisen.png'
import dk from './qc-dk.png'
import keel from './qc-keel.png'
import visor from './qc-visor.png'
import ranger from './qc-ranger.png'

export interface IImage {
  [key: string]: string
}

export const championImage: IImage = {
  Galena: galena,
  Nyx: nyx,
  Sorlag: sorlag,
  'Strogg & Peeker': strogg,
  Slash: slash,
  Scalebearer: scalebearer,
  Anarki: anarki,
  Athena: athena,
  'B. J. Blazkowicz': bj,
  Clutch: clutch,
  'Doom Slayer': doom,
  Eisen: eisen,
  'Death Knight': dk,
  Keel: keel,
  Visor: visor,
  Ranger: ranger,
}
