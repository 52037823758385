import { CSSTransition } from 'react-transition-group'
import { IProps } from './models'
import styles from './Dropdown.module.scss'
import { Backdrop } from '../backdrop/Backdrop'

export function Dropdown(props: IProps) {
  // classes
  const classes: string = [styles.root, props.classes].filter((x) => x !== '').join(' ')

  const containerClasses: string = [
    styles.container,
    props.from ? styles[`from${props.from}`] : styles.fromTop,
    props.disableOpenAnimation ? styles.disableOpenAnimation : '',
    props.disableCloseAnimation ? styles.disableCloseAnimation : '',
  ]
    .filter((x) => x !== '')
    .join(' ')

  // methods
  const close = () => {
    props.onOpenedChange(false)
  }

  return (
    <div className={classes}>
      {!props.disableBackdrop && <Backdrop showed={props.opened} onClose={close}></Backdrop>}
      <CSSTransition
        in={props.opened}
        timeout={
          props!.css && props!.css['--transitionDelayMS']
            ? +props!.css['--transitionDelayMS'].slice(0, props!.css['--transitionDelayMS'].length - 2)
            : 200
        }
        classNames={{
          enter: styles.enter,
          enterActive: styles.enterActive,
          exitActive: styles.exitActive,
          exit: styles.exit,
        }}
        unmountOnExit
      >
        <div className={containerClasses} style={props.css}>
          {props.children}
        </div>
      </CSSTransition>
    </div>
  )
}
