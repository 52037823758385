import './alert.scss'
import { IProps, mergePropsWithDefault } from './alert.model'

export function Alert(props: IProps) {
  // props
  props = mergePropsWithDefault(props)
  // classes
  const alertClasses: string[] = [
    'qc-alert',
    `qc-alert-${props.color}`,
    props.classes || '',
  ].filter((x) => x !== '')

  return (
    <div className={alertClasses.join(' ')} style={props.css}>
      <div className="qc-alert__body">
        <div className="qc-alert__title">{props.title}</div>
        <div className="qc-alert__description">{props.description}</div>
      </div>
    </div>
  )
}
