import vk from './vk.png'
import twitch from './twitch.png'

export interface IImage {
  [key: string]: string
}

export const streamsImage: IImage = {
  vk, twitch
}
