import { createContext } from 'react'

export interface IAuthContext {
  token: string | null
  discordId: string | null
  discordName: string | null
  discordAvatar: string | null
  discordGlobalName: string | null
  isAuthenticated: boolean
}

export const AuthContext = createContext<IAuthContext>({
  token: null,
  discordId: null,
  discordName: null,
  discordAvatar: null,
  discordGlobalName: null,
  isAuthenticated: false,
})
