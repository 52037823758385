import { useRef, useState } from 'react'
import { ValidationStart } from '../../validator/validation.models'
import { IData } from '../../components/component.models'
import { Loader } from '../loader/Loader'
import {
  DataListTypes,
  IProps,
  mergePropsWithDefault,
} from './data-list.models'
import './data-list.scss'

export function DataList(props: IProps) {
  // props
  props = mergePropsWithDefault(props)
  // methods
  const select = (key: number) => {
    if (!props.disabled) {
      const selectedItem: IData | undefined = props.data!.find(
        (x) => x[props.keyField!] === key
      )
      if (selectedItem === undefined) {
        throw Error(`Item (key=${key} does not found)`)
      }
      let result: IData[] = []
      if (props.multiselect) {
        result.push(...props.selectedData!)
        result.includes(selectedItem)
          ? (result = result.filter((x) => x !== selectedItem))
          : result.push(selectedItem)
      } else {
        result.push(selectedItem)
      }
      if (props.validation?.validationStart === ValidationStart.onChange) {
        props.validation.setCanValidate(true)
      }
      props.onSelectedDataChange ? props.onSelectedDataChange(result) : void 0
    }
  }
  // classes
  const selectClasses: string[] = [
    'qc-data-list',
    props.type !== DataListTypes.Default ? `qc-data-list-${props.type}` : '',
    `qc-data-list-${props.color}`,
    props.disabled ? 'qc-data-list-disabled qc-data-list-transition-none' : '',
    props.classes || '',
  ].filter((x) => x !== '')
  //
  let columnCount: number
  let rowCount: number
  if (props.type === DataListTypes.Grid) {
    columnCount = props.columnCount || Math.ceil(Math.sqrt(props.data.length))
    rowCount = Math.ceil(props.data.length / columnCount)
    props.css!['--col-count'] = columnCount
  }

  const urls: string[] = []
  props.data.forEach((x) => {
    urls.push(x.image)
  })

  return (
    <div className={selectClasses.join(' ')} style={props.css}>
      {props.dataLoading ? (
        <div className="d-flex align-items-center justify-content-center">
          <Loader classes="mt-3 mb-3" />
        </div>
      ) : props.dataLoadError ? (
        <div className="qc-data-list__data-load-error">
          {props.dataLoadError}
        </div>
      ) : (
        props.data.length > 0 &&
        props.data.map((item, i) => {
          return (
            <div
              className={`qc-data-list__item ${
                props.showSelected &&
                props.selectedData.find(
                  (x) => x[props.keyField!] === item[props.keyField!]
                )
                  ? 'qc-data-list__item-selected'
                  : ''
              }`}
              onClick={(event) => select(item[props.keyField!])}
            >
              {item.image && (
                <img
                  src={item.image.src}
                  alt={item.image.alt}
                  className="qc-data-list__item-image"
                />
              )}
              <div className="qc-data-list__item-description">
                {item[props.valueField!].trim().length === 0 &&
                props.emptyValue !== null
                  ? props.emptyValue
                  : item[props.valueField!]}
              </div>
            </div>
          )
        })
      )}
    </div>
  )
}
