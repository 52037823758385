import { IProps, mergePropsWithDefault } from './loader.model'
import './loader.scss'

export function Loader(props: IProps) {
  // props
  props = mergePropsWithDefault(props)
  // classes
  const loaderClasses: string[] = [
    'qc-loader',
    `qc-loader-${props.type}`,
    props.classes || '',
  ].filter((x) => x !== '')

  return <div className={loaderClasses.join(' ')} style={props.css}></div>
}
