import './pick-ban-results-info.scss'
import { IProps } from './pick-ban-results-info.model'
import { championImage } from '../../images/champions/champions'
import { Button } from '../../componentsKit/button/Button'
import { faCopy } from '@fortawesome/free-solid-svg-icons'

export function PickBanResultsInfo(props: IProps) {
  // classes
  const pickBanInfoForMapClasses: string[] = [
    'qc-pick-ban-results-info',
    props.classes || '',
  ].filter((x) => x !== '')
  // data
  interface IData {
    [key: string]: string
  }
  const mapLittleNames: IData = {
    Awoken: 'Awoken',
    'Blood Covenant': 'BC',
    'Blood Run': 'BR',
    'Corrupted Keep': 'CK',
    'Deep Embrace': 'DE',
    Exile: 'Exile',
    Insomnia: 'Insomnia',
    'Ruins of Sarnath': 'Ruins',
    'Molten Falls': 'Molten',
    'Tower of Koth': 'Tower',
    'Vale of Pnath': 'Vale',
    'Burial Chamber': 'Burial',
    'Church of Azathoth': 'Azathoth',
    Citadel: 'Citadel',
    Lockbox: 'Lockbox',
    'Tempest Shrine': 'Tempest',
    'The Dark Zone': 'DZ',
    'The Longest Yard': 'LY',
    Crucible: 'Crucible',
    'Cursed Soul': 'Cursed Soul'
  }
  const championLittleNames: IData = {
    Galena: 'Galena',
    Nyx: 'Nyx',
    Sorlag: 'Sorlag',
    'Strogg & Peeker': 'Strogg',
    Slash: 'Slash',
    Scalebearer: 'Scale',
    Anarki: 'Anarki',
    Athena: 'Athena',
    'B. J. Blazkowicz': 'BJ',
    Clutch: 'Clutch',
    'Doom Slayer': 'Doom',
    Eisen: 'Eisen',
    'Death Knight': 'DK',
    Keel: 'Keel',
    Visor: 'Visor',
    Ranger: 'Ranger',
  }

  //methods
  const copyResultLink = () => {
    const result: string[] = [`[${props.player1} - ${props.player2}] `]
    props.stepMapList.forEach((map) => {
      result.push(`[${mapLittleNames[map]}]`)

      let pickedChampions: string[] = []
      props.steps.forEach((step) => {
        if (
          step.map === map &&
          step.player === props.player1 &&
          step.does === 'pick'
        ) {
          pickedChampions.push(`${championLittleNames[step.name]}`)
        }
      })

      pickedChampions.length
        ? result.push(`: ${pickedChampions.join('-')}/`)
        : result.push(' ')

      pickedChampions = []
      props.steps.forEach((step) => {
        if (
          step.map === map &&
          step.player === props.player2 &&
          step.does === 'pick'
        ) {
          pickedChampions.push(`${championLittleNames[step.name]}`)
        }
      })

      pickedChampions.length
        ? result.push(`${pickedChampions.join('-')}, `)
        : result.push(' ')
    })
    const championAllmaps = props.steps.filter(
      (step) =>
        step.what === 'champion' &&
        step.map === undefined &&
        step.does === 'ban'
    )
    if (
      championAllmaps.length > 0 &&
      !props.steps.find((x) => x.what === 'champion' && x.does === 'pick')
    ) {
      result.push(`- Banned: `)
      championAllmaps.forEach((champion) => {
        result.push(`${championLittleNames[champion.name]}, `)
      })
    }

    const resultString = result.join('')
    navigator.clipboard.writeText(
      resultString.substring(0, resultString.length - 2)
    )
  }

  return (
    <div className={pickBanInfoForMapClasses.join(' ')} style={props.css}>
      <>
        <div className="qc-pick-ban-results-info__title">
          <div className="qc-pick-ban-results-info__title-players">
            <span> {props.player1}</span>
            <span className="qc-pick-ban-results-info__title-players-vs">
              vs
            </span>
            <span>{props.player2}</span>
          </div>
          <div className="qc-pick-ban-results-info__title-game-type">
            {props.gameType} {props.bestOf}
          </div>
        </div>

        {props.steps.filter(
          (step) => step.map === undefined && step.what === 'champion'
        ).length > 0 && (
          <>
            <div className="qc-pick-ban-results-info__map">
              <div className="qc-pick-ban-results-info__map-title">
                <div className="qc-pick-ban-results-info__map-title__body">
                  <span className="qc-pick-ban-results-info__map-title__body-map">
                    All maps
                  </span>
                </div>
              </div>

              <div className="qc-pick-ban-results-info__map-champions">
                {props.steps
                  .filter(
                    (step) =>
                      step.map === undefined &&
                      step.player === props.player1 &&
                      step.does === 'ban' &&
                      step.what === 'champion'
                  )
                  .map((step) => (
                    <img
                      src={championImage[step.name]}
                      alt=""
                      className="qc-pick-ban-results-info__map-champions-image qc-pick-ban-results-info__map-champions-image-ban"
                    />
                  ))}

                {props.steps
                  .filter(
                    (step) =>
                      step.map === undefined &&
                      step.player === props.player1 &&
                      step.does === 'pick' &&
                      step.what === 'champion'
                  )
                  .map((step) => (
                    <img
                      src={championImage[step.name]}
                      alt=""
                      className="qc-pick-ban-results-info__map-champions-image qc-pick-ban-results-info__map-champions-image-pick"
                    />
                  ))}

                {props.steps
                  .filter(
                    (step) =>
                      step.map === undefined &&
                      step.player === props.player2 &&
                      step.does === 'pick' &&
                      step.what === 'champion'
                  )
                  .map((step) => (
                    <img
                      src={championImage[step.name]}
                      alt=""
                      className="qc-pick-ban-results-info__map-champions-image qc-pick-ban-results-info__map-champions-image-pick"
                    />
                  ))}

                {props.steps
                  .filter(
                    (step) =>
                      step.map === undefined &&
                      step.player === props.player2 &&
                      step.does === 'ban' &&
                      step.what === 'champion'
                  )
                  .map((step) => (
                    <img
                      src={championImage[step.name]}
                      alt=""
                      className="qc-pick-ban-results-info__map-champions-image qc-pick-ban-results-info__map-champions-image-ban"
                    />
                  ))}
              </div>
            </div>
          </>
        )}

        {props.stepMapList.map((map) => (
          <div className="qc-pick-ban-results-info__map">
            <div className="qc-pick-ban-results-info__map-title">
              <div className="qc-pick-ban-results-info__map-title__body">
                <span className="qc-pick-ban-results-info__map-title__body-map">
                  {map}
                </span>
                <span className="qc-pick-ban-results-info__map-title__body-by">
                  by
                </span>
                <span className="qc-pick-ban-results-info__map-title__body-picked-by">
                  {props.steps.find((step) => step.name === map)?.player}
                </span>
              </div>
            </div>
            <div className="qc-pick-ban-results-info__map-champions">
              <div className="d-flex flex-wrap">
                {props.steps
                  .filter(
                    (step) =>
                      step.map === map &&
                      step.player === props.player1 &&
                      step.does === 'ban'
                  )
                  .map((step) => {
                    const img = new Image()
                    img.src = championImage[step.name]
                    return (
                      <img
                        src={img.src}
                        alt=""
                        className="qc-pick-ban-results-info__map-champions-image qc-pick-ban-results-info__map-champions-image-ban"
                      />
                    )
                  })}

                {props.steps
                  .filter(
                    (step) =>
                      step.map === map &&
                      step.player === props.player1 &&
                      step.does === 'pick'
                  )
                  .map((step) => (
                    <img
                      src={championImage[step.name]}
                      alt=""
                      className="qc-pick-ban-results-info__map-champions-image qc-pick-ban-results-info__map-champions-image-pick"
                    />
                  ))}
              </div>
              <div className="d-flex flex-wrap">
                {props.steps
                  .filter(
                    (step) =>
                      step.map === map &&
                      step.player === props.player2 &&
                      step.does === 'pick'
                  )
                  .map((step) => (
                    <img
                      src={championImage[step.name]}
                      alt=""
                      className="qc-pick-ban-results-info__map-champions-image qc-pick-ban-results-info__map-champions-image-pick"
                    />
                  ))}

                {props.steps
                  .filter(
                    (step) =>
                      step.map === map &&
                      step.player === props.player2 &&
                      step.does === 'ban'
                  )
                  .map((step) => (
                    <img
                      src={championImage[step.name]}
                      alt=""
                      className="qc-pick-ban-results-info__map-champions-image qc-pick-ban-results-info__map-champions-image-ban"
                    />
                  ))}
              </div>
            </div>
          </div>
        ))}

        <div className="qc-pick-ban-results-info__buttons">
          <Button
            text="Copy result string"
            color="orange"
            iconLeft={faCopy}
            onClick={copyResultLink}
          />
        </div>
      </>
    </div>
  )
}
