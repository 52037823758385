import { Routes, Route, BrowserRouter as Router, Navigate } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Frame } from './components/frame/Frame'
import { useAuth } from './hooks/auth.hook'
import { ContactsPage } from './pages/ContactsPage'
import { CreateSessionPage } from './pages/CreateSessionPage'
import { SessionPage } from './pages/SessionPage'
import { SessionsPage } from './pages/SessionsPage'
import { AuthContext } from './context/AuthContext'
import { MySessionsPage } from './pages/MySessionsPage'
import { MyTemplatesPage } from './pages/MyTemplatesPage'
import { MyTemplateEditPage } from './pages/MyTemplateEditPage'
import { store } from './store'
import { CreateSessionPageTest } from './pages/CreateSessionPage/CreateSessionPage'

function App() {
  const { data, loading, error } = useAuth()

  return (
    <AuthContext.Provider
      value={{
        discordId: data ? data.discordId : null,
        discordName: data ? data.discordName : null,
        discordAvatar: data ? data.discordAvatar : null,
        discordGlobalName: data ? data.discordGlobalName : null,
        isAuthenticated: data ? data.isAuthenticated : false,
        token: data ? data.token : null,
      }}
    >
      {!loading && !error && data && (
        <Router>
          <Frame>
            <Routes>
              <Route path="/" element={<CreateSessionPage />} />
              <Route path="/sessions" element={<SessionsPage />} />
              <Route path="/contacts" element={<ContactsPage />} />
              <Route path="/session/:code" element={<SessionPage />} />
              <Route path="/session/:code/:player" element={<SessionPage />} />
              {data.isAuthenticated && (
                <>
                  <Route path="/my-sessions" element={<MySessionsPage />} />
                  <Route path="/my-templates" element={<MyTemplatesPage />} />
                  <Route path="/my-templates/:id" element={<MyTemplateEditPage />} />
                </>
              )}
              <Route path="/*" element={<Navigate replace to="/" />} />
            </Routes>
          </Frame>
        </Router>
      )}
    </AuthContext.Provider>
  )
}

export default App
