import './number-pick-ban.scss'
import { IProps, mergePropsWithDefault } from './number-pick-ban.model'

export function NumberPickBan(props: IProps) {
  // props
  props = mergePropsWithDefault(props)
  // classes
  const numberPickbanClasses: string[] = [
    'qc-number-pick-ban',
    `qc-number-pick-ban-${props.what}`,
    props.color ? `qc-number-pick-ban-${props.color}` : '',
    `qc-number-pick-ban-size-${props.size}`,
    props.classes || '',
  ].filter((x) => x !== '')

  return (
    <div className={numberPickbanClasses.join(' ')} style={props.css}>
      <div className="qc-number-pick-ban__number">{props.number}</div>
      <div className="qc-number-pick-ban__info">
        <div className="qc-number-pick-ban__info-who">{props.who}</div>
        <div className="qc-number-pick-ban__info-does">{props.does}</div>
        <div className="qc-number-pick-ban__info-what">{props.what}</div>
      </div>
    </div>
  )
}
