import { CSSProperties } from 'react'

export interface IProps {
  classes?: string
  css?: CSSProperties
  number?: number
  who: string
  does: string
  what: string
  size?: number
  color?: string
}

export const defaultProps: IProps = {
  who: 'player',
  does: 'pick',
  what: 'the map',
  size: 1,
}

export function mergePropsWithDefault(
  props: IProps,
  defaults: IProps = defaultProps
): IProps {
  const cssProps = { ...defaults.css, ...props.css }
  return {
    ...defaults,
    ...props,
    css: cssProps,
  } as IProps
}
