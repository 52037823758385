import { CSSProperties } from 'react'

export enum PoolType {
  Maps = 'maps',
  Champions = 'champions',
}

export enum PoolStatus {
  Available = 'available',
  Picked = 'picked',
  Banned = 'banned',
  Won = 'won',
}

export interface IProps {
  classes?: string
  css?: CSSProperties
  type: PoolType
  status: PoolStatus
  name: string
}

export interface IContainerProps {
  classes?: string
  css?: CSSProperties
  type: PoolType
  status: PoolStatus
  children: React.ReactNode
}
