import awoken from './qc-awoken.png';
import bc from './qc-bc.png';
import br from './qc-br.png';
import cc from './qc-cc.png';
import de from './qc-de.png';
import exile from './qc-exile.png';
import insomnia from './qc-insomnia.png';
import ros from './qc-ros.png';
import mf from './qc-mf.png';
import tok from './qc-tok.png';
import vop from './qc-vop.png';
import burial from './qc-burial.png';
import coa from './qc-coa.png';
import citadel from './qc-citadel.png';
import lockbox from './qc-lockbox.png';
import ts from './qc-ts.png';
import dz from './qc-dz.png';
import ly from './qc-ly.png';
import cru from './qc-crucible.png';
import cs from './qc-cs.png';

export interface IImage {
  [key: string]: string;
}

export const mapImage: IImage = {
  Awoken: awoken,
  'Blood Covenant': bc,
  'Blood Run': br,
  'Corrupted Keep': cc,
  'Deep Embrace': de,
  Exile: exile,
  Insomnia: insomnia,
  'Ruins of Sarnath': ros,
  'Molten Falls': mf,
  'Tower of Koth': tok,
  'Vale of Pnath': vop,
  'Burial Chamber': burial,
  'Church of Azathoth': coa,
  Citadel: citadel,
  Lockbox: lockbox,
  'Tempest Shrine': ts,
  'The Dark Zone': dz,
  'The Longest Yard': ly,
  Crucible: cru,
  'Cursed Soul': cs,
};
