import buttonStyles from './Button.module.scss'
import { Button } from '../../componentsKitTest/button/Button'

export function BracketButton({ link }: { link: string }) {
  // handlers
  const goToBracketPage = () => {
    window.open(link, '_blank')
  }

  return (
      <Button classes={buttonStyles.root} color="orange" text="Bracket" onClick={goToBracketPage} />
  )
}
