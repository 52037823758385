import React, { CSSProperties } from 'react'

export enum DropdownFrom {
  Top = 'Top',
  Right = 'Right',
  Bottom = 'Bottom',
  Left = 'Left',
}

export interface ICSSProps extends CSSProperties {
  '--top'?: string
  '--left'?: string
  '--translate'?: string
  '--transitionDelayMS'?: string
}

export interface IProps {
  classes?: string
  css?: ICSSProps
  children?: React.ReactNode
  opened: boolean
  onOpenedChange: (opened: boolean) => void
  from?: DropdownFrom
  disableBackdrop?: boolean
  disableOpenAnimation?: boolean
  disableCloseAnimation?: boolean
}
