import { IProps, mergePropsWithDefault } from './button-like-component.models'
import './button-like-component.scss'

export function ButtonLikeComponent(props: IProps) {
  // props
  props = mergePropsWithDefault(props)
  // classes
  const selectClasses: string[] = [
    'qc-button-like-component',
    !props.value ? 'qc-button-like-component-empty' : '',
    `qc-button-like-component-${props.color}`,
    !props.caption ? 'qc-button-like-component-no-caption' : '',
    props.classes || '',
  ].filter((x) => x !== '')
  // handler
  const clickHandler = () => {
    props.onClick ? props.onClick() : void 0
  }

  return (
    <div className={selectClasses.join(' ')} style={props.css}>
      <div className="qc-button-like-component__input" onClick={clickHandler}>
        <div className="qc-button-like-component__input-container">
          {props.caption && (
            <label className="qc-button-like-component__caption">
              {props.caption}
            </label>
          )}
          <label className="qc-button-like-component__value">
            {props.value}
          </label>
        </div>
      </div>
    </div>
  )
}
