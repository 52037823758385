import { Button } from '../../componentsKit/button/Button'
import { IProps, mergePropsWithDefault } from './my-template.model'
import './my-template.scss'
import {
  faPenToSquare as editIcon,
  faTrash as deleteIcon,
} from '@fortawesome/free-solid-svg-icons'
import { useNavigate } from 'react-router-dom'

export function MyTemplate(props: IProps) {
  // props
  props = mergePropsWithDefault(props)
  // hooks - navigate
  const navigate = useNavigate()
  // classes
  const myTemplateClasses: string[] = [
    'qc-my-template',
    props.classes || '',
  ].filter((x) => x !== '')
  // handlers
  const editTemplateHandler = () => {
    navigate(`/my-templates/${props.id}`)
  }

  const deleteClick = () => {
    props.onDelete ? props.onDelete(props.id, props.name) : void 0
  }

  return (
    <div className={myTemplateClasses.join(' ')}>
      <div className="qc-my-template-container">
        <div className="qc-my-template-title">{props.name}</div>
        <div className="qc-my-template-buttons">
          <Button
            color="blue"
            text="Edit"
            iconLeft={editIcon}
            css={{ height: 36 }}
            onClick={editTemplateHandler}
          />
          <Button
            classes="ml-2"
            color="red"
            text="Delete"
            iconLeft={deleteIcon}
            css={{ height: 36 }}
            onClick={deleteClick}
          />
        </div>
      </div>
    </div>
  )
}
