import { IProps } from './model'
import styles from './Button.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export function Button(props: IProps) {
  // classes
  const classes: string = [
    styles.root,
    props.disabled ? styles.disabled : '',
    props.color in styles ? styles[props.color] : '',
    props.classes || '',
  ]
    .filter((x) => x !== '')
    .join(' ')

  return (
    <button className={classes} style={props.css} disabled={props.disabled ? true : false} onClick={props.onClick}>
      {props.iconLeft && <FontAwesomeIcon icon={props.iconLeft!} />}
      {props.imageLeft && <img src={props.imageLeft} alt="" />}
      {props.text}
      {props.iconRight && <FontAwesomeIcon icon={props.iconRight!} />}
      {props.imageRight && <img src={props.imageRight} alt="" />}
    </button>
  )
}
