import { IProps, mergePropsWithDefault } from './modal.model'
import './modal.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { ButtonHTMLAttributes, useContext } from 'react'
import { ModalContext } from './ModalContext'
import { CSSTransition } from 'react-transition-group'

export function Modal(props: IProps) {
  // context
  const { modal, close: closeModal } = useContext(ModalContext)
  // props
  props = mergePropsWithDefault(props)
  // classes
  const modalClasses: string[] = ['qc-modal', props.classes || ''].filter(
    (x) => x !== ''
  )
  // methods
  const close = () => {
    closeModal()
    props.onClose ? props.onClose() : void 0
  }
  // handlers
  const clickHandler = (e: any) => {
    if (e.target.dataset.type === 'backdrop') {
      close()
    }
  }

  return (
    <>
      <CSSTransition
        in={modal}
        timeout={150}
        classNames="qc-modal"
        unmountOnExit
      >
        <div
          className={modalClasses.join(' ')}
          data-type="backdrop"
          onClick={clickHandler}
          style={props.css}
        >
          <div
            className="qc-modal__window"
            style={props.cssWindow}
            data-type="window"
          >
            <div className="qc-modal__window-content">
              {props.closeButton && (
                <FontAwesomeIcon
                  icon={faXmark}
                  className="qc-modal__window-content-close"
                  onClick={close}
                />
              )}
              {props.children}
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}
