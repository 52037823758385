import { useState, useEffect } from 'react'
import axios, { AxiosError } from 'axios'

export interface ICheckLogin {
  isAuthenticated: boolean
  discordId: string | null
  discordName: string | null
  discordAvatar: string | null
  discordGlobalName: string | null
  token: string | null
}

export const useAuth = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [data, setData] = useState<ICheckLogin | null>(null)

  async function fetchCheckLogin() {
    try {
      setError('')
      setLoading(true)
      const response = await axios.get<ICheckLogin>('/discord/auth/checkLogin')
      setData(response.data)
      setLoading(false)
    } catch (e) {
      const error = e as AxiosError
      setLoading(false)
      setError(error.message || 'Fetch data error')
    }
  }

  useEffect(() => {
    fetchCheckLogin()
  }, [])

  return { data, loading, error }
}
