import { CSSTransition } from 'react-transition-group'
import { Alert } from '../componentsKit/alert/Alert'
import { Loader } from '../componentsKit/loader/Loader'
import { SessionInfo } from '../components/session-info/SessionInfo'
import { useSession } from '../hooks/session.hook'
import '../scss/sessions.scss'

export const SessionsPage = () => {
  const {
    sessions,
    loading: loadingSessions,
    error: errorSessions,
  } = useSession()

  return (
    <div className="qc-container qc-sessions-container">
      <div className="qc-content">
        <>
          <div className="qc-content__header">
            <h1>
              Sessions
              <CSSTransition
                in={loadingSessions}
                timeout={300}
                classNames={'qc-loader-container'}
                unmountOnExit
              >
                <div className="qc-loader-container qc-content__header-loader">
                  <Loader />
                </div>
              </CSSTransition>
            </h1>
          </div>
          {errorSessions ? (
            <Alert color="red" title="Error" description={errorSessions} />
          ) : (
            <CSSTransition
              in={!loadingSessions}
              timeout={500}
              classNames={'use-css-transition'}
              unmountOnExit
            >
              <div className="use-css-transition qc-sessions mb-2">
                {sessions.map((session) => (
                  <SessionInfo
                    player1={session.player1}
                    player2={session.player2}
                    spectatorCode={session.spectator_code}
                    gameType={session.gameType.name}
                    bestOf={session.bestOf.name}
                    dateCreate={session.dt_create}
                    template={session.template_name}
                    templateOption={session.template_option_name}
                  />
                ))}
              </div>
            </CSSTransition>
          )}
        </>
      </div>
    </div>
  )
}
