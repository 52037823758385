import { championImage } from '../../images/champions/champions'
import { mapImage } from '../../images/maps/maps'
import { IProps, PoolType } from './pool-info.model'
import './pool-info.scss'

export function PoolInfo(props: IProps) {
  const poolInfoClasses: string[] = [
    'qc-pool-info',
    `qc-pool-info-${props.status}`,
    props.classes || '',
  ].filter((x) => x !== '')

  const getImage = (): React.ReactNode => {
    const img = new Image()
    img.src =
      props.type === PoolType.Maps
        ? mapImage[props.name]
        : championImage[props.name]
    return <img src={img.src} alt="" className="qc-pool-info__body-image" />
  }

  return (
    <div className={poolInfoClasses.join(' ')} style={props.css}>
      <div className="qc-pool-info__body">
        <div className="qc-pool-info__body-what">{props.name}</div>
        {getImage()}
      </div>
    </div>
  )
}
