import React, { CSSProperties } from 'react'

export interface IProps {
  classes?: string
  css?: CSSProperties
  name: string
  id: number
  //
  onDelete?: (id: number, name: string) => void
}

export const defaultProps: IProps = {
  name: 'My template',
  id: 0,
}

export function mergePropsWithDefault(
  props: IProps,
  defaults: IProps = defaultProps
): IProps {
  const cssProps = { ...defaults.css, ...props.css }
  return {
    ...defaults,
    ...props,
    css: cssProps,
  } as IProps
}
