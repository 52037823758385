import './map-algo.scss';
import { IProps, mergePropsWithDefault } from './map-algo.model';
import { NumberPickBan } from '../number-pick-ban/NumberPickBan';

export function MapAlgo(props: IProps) {
  // props
  props = mergePropsWithDefault(props);
  // classes
  const mapAlgoClasses: string[] = ['qc-map-algo', props.classes || ''].filter((x) => x !== '');

  return (
    <div className={mapAlgoClasses.join(' ')} style={props.css}>
      {getCodeByAlgo(props.mapAlgo, props.bestOf, props.gameType, props.mapCount, props.firstMap)}
    </div>
  );
}

const getCodeByAlgo = (mapAlgo: number, bestOf: number, gameType: number, mapCount: number, firstMapID: number) => {
  const code = [];
  let stepNumber = 1;
  let picked = 0;
  let who = gameType === 9 ? 'Player' : 'Team';
  let playerNumber = firstMapID === 3 ? 2 : 1;

  switch (mapAlgo) {
    case 1:
      if ([4, 6].includes(bestOf)) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="won" what="map" color="orange" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
      }
      while (stepNumber <= mapCount - bestOf) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="ban" what="map" color="red" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
      }
      while (picked < bestOf) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="pick" what="map" color="green" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
        picked++;
      }
      break;

    case 2:
      if ([4, 6].includes(bestOf)) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="won" what="map" color="orange" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
      }
      while (picked < bestOf) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="ban" what="map" color="red" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="ban" what="map" color="red" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="pick" what="map" color="green" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
        picked++;
        if (picked < bestOf) {
          code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="pick" what="map" color="green" />);
          stepNumber++;
          playerNumber = playerNumber === 1 ? 2 : 1;
          picked++;
        }
      }
      break;

    case 3:
      if ([4, 6].includes(bestOf)) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="won" what="map" color="orange" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
        mapCount--;
      }
      let x = Math.ceil((mapCount - bestOf) / Math.ceil(0.5 * bestOf));
      if (x % 2 !== 0) {
        x--;
      }
      const z = Math.floor(bestOf % 2 !== 0 ? 0.5 * bestOf : 0.5 * (bestOf - 1));

      for (let i = 0; i < z; i++) {
        for (let j = 1; j <= 0.5 * x; j++) {
          code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="ban" what="map" color="red" />);
          playerNumber = playerNumber === 1 ? 2 : 1;
          code.push(<NumberPickBan number={stepNumber + 1} who={`${who} ${playerNumber}`} does="ban" what="map" color="red" />);
          stepNumber += 2;
        }
        playerNumber = playerNumber === 1 ? 2 : 1;
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="pick" what="map" color="green" />);
        playerNumber = playerNumber === 1 ? 2 : 1;
        code.push(<NumberPickBan number={stepNumber + 1} who={`${who} ${playerNumber}`} does="pick" what="map" color="green" />);
        playerNumber = playerNumber === 1 ? 2 : 1;
        stepNumber += 2;
        picked += 2;
      }
      const y = mapCount - (bestOf - picked) - z * (x + 2);
      for (let i = 0; i < y; i++) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="ban" what="map" color="red" />);
        playerNumber = playerNumber === 1 ? 2 : 1;
        stepNumber++;
      }
      while (picked < bestOf) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="pick" what="map" color="green" />);
        picked++;
        playerNumber = playerNumber === 1 ? 2 : 1;
        stepNumber++;
      }
      break;

    case 4:
      code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="won" what="map" color="orange" />);
      stepNumber++;
      playerNumber = playerNumber === 1 ? 2 : 1;
      code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="pick" what="map" color="green" />);
      stepNumber++;
      playerNumber = playerNumber === 1 ? 2 : 1;
      for (let i = 0; i < 2; i++) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="ban" what="map" color="red" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
      }
      for (let i = 0; i < 3; i++) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="pick" what="map" color="green" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
      }
      break;

    case 5:
      if ([4, 6].includes(bestOf)) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="won" what="map" color="orange" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
      }
      for (let i = 0; i < 2; i++) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="ban" what="map" color="red" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
      }
      for (let i = 0; i < 2; i++) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="pick" what="map" color="green" />);
        stepNumber++;
        bestOf--;
        playerNumber = playerNumber === 1 ? 2 : 1;
      }
      while (stepNumber <= mapCount - bestOf) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="ban" what="map" color="red" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
      }
      while (picked < bestOf) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="pick" what="map" color="green" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
        picked++;
      }
      break;

    case 6:
      if ([4, 6].includes(bestOf)) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="won" what="map" color="orange" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
      }
      for (let i = 0; i < 2; i++) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="pick" what="map" color="green" />);
        stepNumber++;
        bestOf--;
        playerNumber = playerNumber === 1 ? 2 : 1;
      }
      while (stepNumber <= mapCount - bestOf) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="ban" what="map" color="red" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
      }
      while (picked < bestOf) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="pick" what="map" color="green" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
        picked++;
      }
      break;

    case 7:
      if ([4, 6].includes(bestOf)) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="won" what="map" color="orange" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
      }
      for (let i = 0; i < 4; i++) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="ban" what="map" color="red" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
      }
      for (let i = 0; i < 2; i++) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="pick" what="map" color="green" />);
        stepNumber++;
        bestOf--;
        playerNumber = playerNumber === 1 ? 2 : 1;
      }
      while (stepNumber <= mapCount - bestOf) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="ban" what="map" color="red" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
      }
      while (picked < bestOf) {
        code.push(<NumberPickBan number={stepNumber} who={`${who} ${playerNumber}`} does="pick" what="map" color="green" />);
        stepNumber++;
        playerNumber = playerNumber === 1 ? 2 : 1;
        picked++;
      }
      break;

    default:
      break;
  }
  return code;
};
