import { useEffect, useState, useContext } from 'react'
import axios, { AxiosError } from 'axios'
import { AuthContext } from '../context/AuthContext'
import {
  IBestOf,
  IBestOfChampionAlgo,
  IBestOfMapAlgo,
  IChampion,
  IFirst,
  IGameType,
  IMap,
} from './dataForCreateSession.hook'
import { mapImage } from '../images/maps/maps'
import { championImage } from '../images/champions/champions'

export interface ITemplate {
  id: number
  name: string
  sort_param: number
  is_default: boolean
  creatorUserID?: number
  templateOption: ITemplateOption[]
  is_private?: boolean
}

export interface ITemplateOption {
  id: number
  name: string
  templateID: number
  best_ofID: number
  game_typeID: number
  map_algoID: number
  champion_algoID: number
  first_mapID: number
  first_championID: number
  is_default: boolean
  sort_param: number
  maps: number[]
  champions: number[]
}

interface IData {
  map: IMap[]
  champion: IChampion[]
  bestOf: IBestOf[]
  gameType: IGameType[]
  bestOfMapAlgo: IBestOfMapAlgo[]
  bestOfChampionAlgo: IBestOfChampionAlgo[]
  first: IFirst[]
}

interface IFullData {
  template: ITemplate
  data: IData
}

export function useMyTemplate(id: string) {
  const [myTemplateData, setMyTemplateData] = useState<IFullData | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<any>()
  const { token } = useContext(AuthContext)

  interface IErrorData {
    message: string
  }

  async function fetchTemplate() {
    try {
      setError('')
      setLoading(true)
      const response = await axios.get<IFullData>(
        `/my-templates/getMyTemplate/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const map = response.data.data.map.map((x) => {
        const img = new Image()
        img.src = mapImage[x.name]
        img.alt = ''
        return { ...x, image: img }
      })
      const champion = response.data.data.champion.map((x) => {
        const img = new Image()
        img.src = championImage[x.name]
        img.alt = ''
        return { ...x, image: img }
      })
      setMyTemplateData({
        template: response.data.template,
        data: {
          map,
          champion,
          bestOf: response.data.data.bestOf,
          gameType: response.data.data.gameType,
          bestOfMapAlgo: response.data.data.bestOfMapAlgo,
          bestOfChampionAlgo: response.data.data.bestOfChampionAlgo,
          first: response.data.data.first,
        },
      })
      setLoading(false)
    } catch (e) {
      const error = e as AxiosError
      const message = error.response!.data as IErrorData

      setLoading(false)
      setError(message.message || 'Fetch data error')
    }
  }

  useEffect(() => {
    fetchTemplate()
  }, [])

  return { myTemplateData, loading, error }
}
