import { Button } from '../../componentsKit/button/Button'
import { DataList } from '../../componentsKit/data-list/DataList'
import { IProps, mergePropsWithDefault } from './template-options-editor.models'
import './template-options-editor.scss'
import {
  faPlus as newOptionIcon,
  faTrash as deleteOptionIcon,
  faChevronDown as downIcon,
  faChevronUp as upIcon,
} from '@fortawesome/free-solid-svg-icons'

export function TemplateOptionsEditor(props: IProps) {
  // props
  props = mergePropsWithDefault(props)
  // classes
  const templateOptionsEditorClasses: string[] = [
    'qc-template-options-editor',
    `qc-template-options-editor-${props.color}`,
    props.disabled
      ? 'qc-template-options-editor-disabled qc-template-options-editor-transition-none'
      : '',
    props.classes || '',
  ].filter((x) => x !== '')

  return (
    <div className={templateOptionsEditorClasses.join(' ')}>
      <label className="qc-template-options-editor__caption">
        {props.caption}
      </label>
      <div className="qc-template-options-editor__option-container">
        <DataList {...props.dataList} disabled={props.disabled} />
      </div>
      <div className="qc-template-options-editor__buttons">
        <Button
          iconLeft={newOptionIcon}
          onClick={props.createTemplateOption}
          disabled={props.disabled}
        />
        <Button
          iconLeft={upIcon}
          onClick={props.upTemplateOption}
          disabled={props.disabled}
        />
        <Button
          iconLeft={downIcon}
          onClick={props.downTemplateOption}
          disabled={props.disabled}
        />
        <Button
          iconLeft={deleteOptionIcon}
          onClick={props.deleteTemplateOption}
          disabled={props.disabled}
        />
      </div>
    </div>
  )
}
