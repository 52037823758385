import { defaultValidationProps, IValidation } from './validation.models'

export class Validation {
  props: IValidation

  constructor(props: IValidation) {
    this.props = { ...defaultValidationProps, ...props }
  }

  get errors(): string[] {
    const errors: string[] = []
    this.props.checks.forEach((check) => {
      if (!check.isValid) {
        errors.push(check.error)
      }
    })
    return errors
  }

  get isValid(): boolean {
    return !(this.errors.length > 0)
  }
}
