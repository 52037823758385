import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { CSSProperties } from 'react'
import { IValidation } from '../../validator/validation.models'

export interface IProps {
  classes?: string
  css?: CSSProperties
  editValue: string
  caption?: string
  maxLength?: number
  readOnly?: boolean
  validation?: IValidation
  isValid?: boolean
  errors?: string[]
  //
  onEditValueChange: (value: string) => void
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
}

export const defaultProps: IProps = {
  editValue: '',
  onEditValueChange: () => void 0,
}

export function mergePropsWithDefault(
  props: IProps,
  defaults: IProps = defaultProps
): IProps {
  const cssProps: CSSProperties = {
    ...defaults.css,
    ...props.css,
  }
  return {
    ...defaults,
    ...props,
    css: cssProps,
  } as IProps
}
