import { useContext, useState } from 'react'
import { AuthContext } from '../../context/AuthContext'
import { faCaretDown as ArrowIcon } from '@fortawesome/free-solid-svg-icons'
import { faRightFromBracket as LogoutIcon } from '@fortawesome/free-solid-svg-icons'
import buttonStyles from './Button.module.scss'
import dropdownStyles from './Dropdown.module.scss'
import { NavLink } from 'react-router-dom'
import { Button } from '../../componentsKitTest/button/Button'
import { Dropdown } from '../../componentsKitTest/dropdown/Dropdown'
import { DropdownFrom } from '../../componentsKitTest/dropdown/models'

export function DiscordUserMenu() {
  // context
  const auth = useContext(AuthContext)
  // hooks
  const [opened, setOpened] = useState<boolean>(false)
  // methods
  const open = () => {
    setOpened(true)
  }
  const close = () => {
    setOpened(false)
  }
  const toggle = () => {
    opened ? close() : open()
  }
  //handlers
  const openedChangeHandler = (opened: boolean) => {
    setOpened(opened)
  }
  const logoutHandler = () => {
    const host = process.env.NODE_ENV !== 'production' ? `${process.env.REACT_APP_SERVER_ENDPOINT_DEV}` : ''
    window.location.href = `${host}/discord/auth/logout`
  }

  return (
    <div>
      <Button
        classes={buttonStyles.root}
        color="indigo"
        text={auth.discordGlobalName ? auth.discordGlobalName : ''}
        imageLeft={
          auth.discordAvatar && auth.discordId
            ? `https://cdn.discordapp.com/avatars/${auth.discordId}/${auth.discordAvatar}`
            : ''
        }
        iconRight={ArrowIcon}
        onClick={toggle}
      />
      <Dropdown
        opened={opened}
        onOpenedChange={openedChangeHandler}
        from={DropdownFrom.Bottom}
        css={{ '--top': '20px', '--left': 'calc(100% - 164px)' }}
      >
        <div className={dropdownStyles.root}>
          <NavLink className={dropdownStyles.item} to="/my-sessions" onClick={() => close()}>
            My sessions
          </NavLink>
          <NavLink className={dropdownStyles.item} to="/my-templates" onClick={() => close()}>
            My templates
          </NavLink>
          <Button
            color="red"
            text="Log out"
            classes="mt-2"
            css={{ width: 140, height: 36 }}
            iconRight={LogoutIcon}
            onClick={logoutHandler}
          />
        </div>
      </Dropdown>
    </div>
  )
}
