import './champion-algo.scss'
import { IProps, mergePropsWithDefault } from './champion-algo.model'
import { NumberPickBan } from '../number-pick-ban/NumberPickBan'

export function ChampionAlgo(props: IProps) {
  // props
  props = mergePropsWithDefault(props)
  // classes
  const championAlgoClasses: string[] = [
    'qc-champion-algo',
    props.classes || '',
  ].filter((x) => x !== '')

  return (
    <div className={championAlgoClasses.join(' ')} style={props.css}>
      {getCodeByAlgo(
        props.championAlgo,
        props.bestOf,
        props.gameType,
        props.firstMapID,
        props.firstChampionID
      )}
    </div>
  )
}

const getCodeByAlgo = (
  championAlgo: number,
  bestOf: number,
  gameType: number,
  firstMapID: number,
  firstChampionID: number
) => {
  const code = []
  let stepNumber = 1
  let who = gameType === 9 ? 'Player' : 'Team'
  let playerNumberForMaps = firstMapID === 3 ? 2 : 1
  let playerNumber =
    firstChampionID <= 5
      ? 1
      : firstChampionID === 6
      ? 2
      : firstChampionID === 7
      ? playerNumberForMaps
      : playerNumberForMaps === 1
      ? 2
      : 1

  let player1 = playerNumber
  let player2 = player1 === 1 ? 2 : 1

  switch (championAlgo) {
    case 1:
      for (let mapNumber = 1; mapNumber <= bestOf; mapNumber++) {
        code.push(
          <div className="qc-champion-algo__map">
            <span className="qc-champion-algo__map-title">
              Picked map {mapNumber}
            </span>
            <NumberPickBan
              number={stepNumber}
              who={`${who} ${player1}`}
              does="ban"
              what="champion"
              color="red"
            />
            <NumberPickBan
              number={stepNumber + 1}
              who={`${who} ${player2}`}
              does="ban"
              what="champion"
              color="red"
            />
            <NumberPickBan
              number={stepNumber + 2}
              who={`${who} ${player1}`}
              does="pick"
              what="champion"
              color="green"
            />
            <NumberPickBan
              number={stepNumber + 3}
              who={`${who} ${player2}`}
              does="pick"
              what="champion"
              color="green"
              classes="mb-1"
            />
          </div>
        )
        stepNumber += 4
        player1 = player1 === 1 ? 2 : 1
        player2 = player1 === 1 ? 2 : 1
      }
      break
    case 2:
      for (let mapNumber = 1; mapNumber <= bestOf; mapNumber++) {
        code.push(
          <div className="qc-champion-algo__map">
            <span className="qc-champion-algo__map-title">
              Picked map {mapNumber}
            </span>
            <NumberPickBan
              number={stepNumber}
              who={`${who} ${player1}`}
              does="ban"
              what="champion"
              color="red"
            />
            <NumberPickBan
              number={stepNumber + 1}
              who={`${who} ${player2}`}
              does="pick"
              what="champion"
              color="green"
            />
            <NumberPickBan
              number={stepNumber + 2}
              who={`${who} ${player1}`}
              does="pick"
              what="champion"
              color="green"
              classes="mb-1"
            />
          </div>
        )
        stepNumber += 3
        player1 = player1 === 1 ? 2 : 1
        player2 = player1 === 1 ? 2 : 1
      }
      break
    case 3:
      code.push(
        <div className="qc-champion-algo__map">
          <span className="qc-champion-algo__map-title">All maps</span>
          <NumberPickBan
            number={stepNumber}
            who={`${who} ${playerNumber == 1 ? 1 : 2}`}
            does="ban"
            what="champion"
            color="red"
          />
          <NumberPickBan
            number={stepNumber + 1}
            who={`${who} ${playerNumber == 1 ? 2 : 1}`}
            does="ban"
            what="champion"
            color="red"
            classes="mb-1"
          />
        </div>
      )
      break
    case 4:
      code.push(
        <div className="qc-champion-algo__map">
          <span className="qc-champion-algo__map-title">All maps</span>
          <NumberPickBan
            number={stepNumber}
            who={`${who} ${player1}`}
            does="ban"
            what="champion"
            color="red"
          />
          <NumberPickBan
            number={stepNumber + 1}
            who={`${who} ${player2}`}
            does="ban"
            what="champion"
            color="red"
            classes="mb-1"
          />
        </div>
      )
      stepNumber += 2
      for (let mapNumber = 1; mapNumber <= bestOf; mapNumber++) {
        code.push(
          <div className="qc-champion-algo__map">
            <span className="qc-champion-algo__map-title">
              Picked map {mapNumber}
            </span>
            <NumberPickBan
              number={stepNumber}
              who={`${who} ${player1}`}
              does="pick"
              what="champion"
              color="green"
            />
            <NumberPickBan
              number={stepNumber + 1}
              who={`${who} ${player2}`}
              does="pick"
              what="champion"
              color="green"
              classes="mb-1"
            />
          </div>
        )
        stepNumber += 2
        player1 = player1 === 1 ? 2 : 1
        player2 = player1 === 1 ? 2 : 1
      }
      break
    case 5:
      code.push(
        <div className="qc-champion-algo__map">
          <span className="qc-champion-algo__map-title">All maps</span>
          <NumberPickBan
            number={stepNumber}
            who={`${who} ${player1}`}
            does="ban"
            what="champion"
            color="red"
          />
          <NumberPickBan
            number={stepNumber + 1}
            who={`${who} ${player2}`}
            does="ban"
            what="champion"
            color="red"
            classes="mb-1"
          />
        </div>
      )
      stepNumber += 2
      for (let mapNumber = 1; mapNumber <= bestOf; mapNumber++) {
        code.push(
          <div className="qc-champion-algo__map">
            <span className="qc-champion-algo__map-title">
              Picked map {mapNumber}
            </span>
            <NumberPickBan
              number={stepNumber}
              who={`${who} ${player1}`}
              does="pick"
              what="champion"
              color="green"
            />
            <NumberPickBan
              number={stepNumber + 1}
              who={`${who} ${player2}`}
              does="pick"
              what="champion"
              color="green"
              classes="mb-1"
            />
            <NumberPickBan
              number={stepNumber + 2}
              who={`${who} ${player1}`}
              does="pick"
              what="champion"
              color="green"
            />
            <NumberPickBan
              number={stepNumber + 3}
              who={`${who} ${player2}`}
              does="pick"
              what="champion"
              color="green"
              classes="mb-1"
            />
          </div>
        )
        stepNumber += 4
        player1 = player1 === 1 ? 2 : 1
        player2 = player1 === 1 ? 2 : 1
      }
      break
    default:
      break
  }

  return code
}
