import React, { useState } from 'react'
import { IProps, mergePropsWithDefault } from './text-edit.models'
import './text-edit.scss'
import { ValidationStart } from '../../validator/validation.models'
import { Alert } from '../alert/Alert'

export function TextEdit(props: IProps) {
  // props
  props = mergePropsWithDefault(props)
  // hooks
  const [focused, setFocused] = useState(false)
  // handlers
  const focusHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(true)
    props.onFocus ? props.onFocus(e) : void 0
  }
  const blurHandler = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocused(false)
    if (props.validation?.validationStart === ValidationStart.onBlur) {
      props.validation.setCanValidate!(true)
    }
    props.onBlur ? props.onBlur(e) : void 0
  }
  const editValueChnagingHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onEditValueChange(e.currentTarget.value)
    if (props.validation?.validationStart === ValidationStart.onChange) {
      props.validation.setCanValidate!(true)
    }
  }
  // classes
  const textEditClasses: string[] = [
    'qc-text-edit',
    focused ? 'qc-text-edit-focused' : '',
    props.editValue.length === 0 ? 'qc-text-edit-empty' : '',
    props.validation?.canValidate && props.isValid === false
      ? 'qc-text-edit-error'
      : '',
    !props.caption ? 'qc-text-edit-no-caption' : '',
    props.classes || '',
  ].filter((x) => x !== '')

  return (
    <div className={textEditClasses.join(' ')}>
      <div className="qc-text-edit__input">
        <div className="qc-text-edit__input-container">
          <input
            className="qc-text-edit__value"
            type="text"
            value={props.editValue}
            maxLength={props.maxLength}
            readOnly={props.readOnly}
            onChange={editValueChnagingHandler}
            onFocus={focusHandler}
            onBlur={blurHandler}
          />
          {props.caption && (
            <label className="qc-text-edit__caption">{props.caption}</label>
          )}
        </div>
      </div>
      <span className="qc-text-edit__bar"></span>
      {props.validation?.canValidate && props.isValid === false && (
        <Alert description={props.errors![0]} color="red" classes="mt-1" />
      )}
    </div>
  )
}
