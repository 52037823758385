import { useEffect, useState, useContext } from 'react'
import axios, { AxiosError } from 'axios'
import { mapImage } from '../images/maps/maps'
import { championImage } from '../images/champions/champions'
import { AuthContext } from '../context/AuthContext'

export interface IMap {
  id: number
  name: string
  is_default: boolean
}

export interface IChampion {
  id: number
  name: string
  type: string
  is_default: boolean
}

export interface IBestOf {
  id: number
  name: string
  map_count: number
  is_default: boolean
}

export interface IGameType {
  id: number
  name: string
  is_default: boolean
}

export interface IBestOfMapAlgo {
  id: number
  best_ofID: number
  game_typeID: number
  map_algoID: number
  min_maps: number
  is_default: boolean
  name: string
}

export interface IBestOfChampionAlgo {
  id: number
  best_ofID: number
  game_typeID: number
  champion_algoID: number
  min_champions: number
  is_default: boolean
  name: string
}

interface ITemplate {
  id: number
  name: string
  is_default: boolean
  bracket_link: string | null
}

interface ITemplateOption {
  id: number
  name: string
  templateID: number
  best_ofID: number
  game_typeID: number
  map_algoID: number
  champion_algoID: number
  is_default: boolean
  sort_param: number
  gameType: {
    name: string
  }
  bestOf: {
    name: string
    map_count: number
  }
  first_mapID: number
  firstMap: {
    name: string
  }
  first_championID: number
  firstChampion: {
    name: string
  }
  maps: number[]
  champions: number[]
}

export interface IFirst {
  id: number
  name: string
  type: string
  is_default: boolean
}

interface IData {
  map: IMap[]
  champion: IChampion[]
  bestOf: IBestOf[]
  gameType: IGameType[]
  bestOfMapAlgo: IBestOfMapAlgo[]
  bestOfChampionAlgo: IBestOfChampionAlgo[]
  template: ITemplate[]
  templateOption: ITemplateOption[]
  first: IFirst[]
}

export function useDataForCreateSession() {
  const [data, setData] = useState<IData>()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { token } = useContext(AuthContext)

  async function fetchDataForCreateSession() {
    try {
      setError('')
      setLoading(true)
      const response = await axios.get<IData>(
        '/session/getDataForCreateSession',
        {
          headers: {
            Authorization: token ? `Bearer ${token}` : '',
          },
        }
      )
      const map = response.data.map.map((x) => {
        const img = new Image()
        img.src = mapImage[x.name]
        img.alt = ''
        return { ...x, image: img }
      })
      const champion = response.data.champion.map((x) => {
        const img = new Image()
        img.src = championImage[x.name]
        img.alt = ''
        return { ...x, image: img }
      })
      setData({
        map,
        champion,
        bestOf: response.data.bestOf,
        gameType: response.data.gameType,
        bestOfMapAlgo: response.data.bestOfMapAlgo,
        bestOfChampionAlgo: response.data.bestOfChampionAlgo,
        template: response.data.template,
        templateOption: response.data.templateOption,
        first: response.data.first,
      })
      setLoading(false)
    } catch (e) {
      const error = e as AxiosError
      setLoading(false)
      setError(error.message || 'Fetch data error')
    }
  }

  useEffect(() => {
    fetchDataForCreateSession()
  }, [])

  return { data, loading, error }
}
