import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { IData } from '../components/component.models'
import { DataListTypes } from '../componentsKit/data-list/data-list.models'
import { IProps as IDataListProps } from '../componentsKit/data-list/data-list.models'
import useWebSocket from '../hooks/webSocket.hook'
import '../scss/session.scss'
import { IRequestStep, IRequestStepTarget, ISessionRequestData, IStep } from '../models/session.model'
import { mapImage } from '../images/maps/maps'
import { Button } from '../componentsKit/button/Button'
import { IProps as IButtonProps } from '../componentsKit/button/button.models'
import { championImage } from '../images/champions/champions'
import { PickBanInfoForMap } from '../components/pick-ban-info-for-map/PickBanInfoForMap'
import { Alert } from '../componentsKit/alert/Alert'
import { PickBanResultsInfo } from '../components/pick-ban-results-info/PickBanResultsInfo'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { ModalContext } from '../componentsKit/modal/ModalContext'
import useSound from 'use-sound'
import sound from '../sounds/ready.wav'
import { DoneStepsInfo } from '../components/done-steps-info/DoneStepsInfo'
import { IProps as INextStepProps } from '../components/next-step-info/next-step-info.model'
import { NextStepInfo } from '../components/next-step-info/NextStepInfo'
import { CSSTransition } from 'react-transition-group'
import { PoolInfoContainer } from '../components/pool-info/PoolnfoContainer'
import { PoolStatus, PoolType } from '../components/pool-info/pool-info.model'
import { PoolInfo } from '../components/pool-info/PoolInfo'
import { StreamsMenu } from '../componentsTest/streamsMenu/StreamsMenu'
import { BracketButton } from '../componentsTest/bracketButton/BracketButton'

export const SessionPage = () => {
  const code: string | undefined = useParams().code
  const player: string | undefined = useParams().player
  const [steps, setSteps] = useState<IStep[]>([])
  const [nextStep, setNextStep] = useState<IStep | null | undefined>(undefined)
  const [stepMapList, setStepMapList] = useState<string[]>([])
  const [text, setText] = useState('')
  const [color, setColor] = useState('')
  const [role, setRole] = useState<string | null>(null)
  const [data, setData] = useState<IData[]>([])
  const ws = useWebSocket()
  const { open: openModal, close: closeModal } = useContext(ModalContext)
  const [play] = useSound(sound)
  const [selectedData, setSelectedData] = useState<IData[]>([])
  const [buttonDisabled, setButtonDisabled] = useState(false)

  const sendData = (step: IRequestStep | null) => {
    const object: ISessionRequestData = {
      code: code || null,
      player: player || null,
      step: step,
    }
    const result: string = JSON.stringify(JSON.stringify(object))
    ws.send(result)
  }

  const genNextStepTextAndColor = (does: string, what: string) => {
    switch (does) {
      case 'pick':
        return { text: `Pick ${what}`, color: 'green' }
      case 'ban':
        return { text: `Ban ${what}`, color: 'red' }
      case 'won':
        return { text: `Won ${what}`, color: 'orange' }
      default:
        return { text: `Unknown ${what}`, color: 'green' }
    }
  }

  useEffect(() => {
    if (ws.data && !ws.data.error) {
      setSelectedData([])
      const steps: IStep[] = []
      const mapList: Set<string> = new Set()
      if (ws.data.model.champion) {
        if (ws.data.model.champion.pick) {
          ws.data.model.champion.pick.forEach((champion: any) => {
            steps[champion.step - 1] = {
              name: champion.name,
              player: champion.player,
              does: 'pick',
              what: 'champion',
              role: champion.role,
            }
          })
        }
        if (ws.data.model.champion.ban) {
          ws.data.model.champion.ban.forEach((champion: any) => {
            steps[champion.step - 1] = {
              name: champion.name,
              player: champion.player,
              does: 'ban',
              what: 'champion',
              role: champion.role,
            }
          })
        }
      }
      ws.data.model.map.pick.forEach((map: any) => {
        mapList.add(map.name)
        steps[map.step - 1] = {
          name: map.name,
          player: map.player,
          does: 'pick',
          what: 'map',
          role: map.role,
        }
        if (map.champion) {
          if (map.champion.pick) {
            map.champion.pick.forEach((champion: any) => {
              steps[champion.step - 1] = {
                name: champion.name,
                player: champion.player,
                does: 'pick',
                what: 'champion',
                map: map.name,
                role: champion.role,
              }
            })
          }
          if (map.champion.ban) {
            map.champion.ban.forEach((champion: any) => {
              steps[champion.step - 1] = {
                name: champion.name,
                player: champion.player,
                does: 'ban',
                what: 'champion',
                map: map.name,
                role: champion.role,
              }
            })
          }
        }
      })

      ws.data.model.map.ban.forEach((map: any) => {
        steps[map.step - 1] = {
          name: map.name,
          player: map.player,
          does: 'ban',
          what: 'map',
          role: map.role,
        }
      })

      ws.data.model.map.won?.forEach((map: any) => {
        steps[map.step - 1] = {
          name: map.name,
          player: map.player,
          does: 'won',
          what: 'map',
          role: map.role,
        }
      })
      setSteps(steps)
      setStepMapList(Array.from(mapList))
      const nextStep: IStep | undefined = steps.find((step: IStep) => step.name === null)
      nextStep ? setNextStep(nextStep) : setNextStep(null)

      if (nextStep) {
        const { text, color } = genNextStepTextAndColor(nextStep.does, nextStep.what)
        setText(text)
        setColor(color)
        setRole(ws.data.role)
        if (ws.data.role === nextStep.role) {
          setData(
            nextStep.what === 'map'
              ? ws.data.maps.map((x) => {
                  const img = new Image()
                  img.src = mapImage[x.name]
                  img.alt = ''
                  return { id: x.id, value: x.name, image: img }
                })
              : ws.data.champions.map((x) => {
                  const img = new Image()
                  img.src = championImage[x.name]
                  img.alt = ''
                  return {
                    id: x.id,
                    value: x.name,
                    image: img,
                  }
                })
          )
        } else {
          setData([])
        }
      }
      if (ws.data.stepDone) {
        play()
      }
    }
  }, [ws.data])

  useEffect(() => {
    if (ws.ready) {
      sendData(null)
    }
  }, [ws.ready])

  useEffect(() => {
    if (selectedData.length > 0) {
      setButtonDisabled(false)
    }
  }, [selectedData])

  useEffect(() => {
    scrollToNextStep()
  })

  // data list
  const selectHandler = (data: IData[]) => {
    setSelectedData(data)
    scrollToNextStep()
    closeModal()
  }
  const dataListProps: IDataListProps = {
    data: data,
    selectedData: selectedData,
    onSelectedDataChange: selectHandler,
    showSelected: false,
    type: DataListTypes.Grid,
  }

  // button
  const clickHandler = () => {
    setButtonDisabled(true)
    const target: IRequestStepTarget = {
      id: selectedData[0].id,
      name: selectedData[0].value,
    }
    const step: IRequestStep = {
      who: nextStep!.role,
      does: nextStep!.does,
      what: nextStep!.what,
      target,
    }
    sendData(step)
  }
  const buttonProps: IButtonProps = {
    text: text,
    css: { width: 280 },
    onClick: clickHandler,
    color: color,
    disabled: buttonDisabled,
  }

  // next step
  const nextStepProps: INextStepProps = {
    nextStep: nextStep,
    role: role,
    select: {
      classes: 'mt-2',
      caption: text,
      color: color,
      onClick: openModal,
      value: selectedData.length > 0 ? selectedData[0].value : '',
    },
    dataList: dataListProps,
    button: buttonProps,
  }

  //

  function scrollToNextStep(): void {
    if (nextStep !== null) {
      const htmlNode = document.querySelector('html')
      const node = document.querySelector('.champions-container')
      if (node && htmlNode) {
        const scroll = 62 + 79 + 49 + node.clientHeight - htmlNode.clientHeight + 40
        if (scroll > 0) {
          window.scrollTo(0, scroll)
        }
      }
    }
  }

  return (
    <>
      {
        <CSSTransition in={ws.data && !ws.data.error} timeout={500} classNames={'qc-session'} unmountOnExit>
          <div className="qc-session">
            {ws.data && !ws.data.error && (
              <>
                <div className="qc-session__header">
                  <div className="qc-session__header-container">
                    <div className="qc-session__header-info">
                      <span className="qc-session__header-info-players">
                        <span>{ws.data.player1}</span>
                        <span className="qc-session__header-info-players-vs">vs</span>
                        <span>{ws.data.player2}</span>
                      </span>
                      <span className="qc-session__header-info-game-type d-flex flex-wrap justify-content-center">
                        {ws.data.template_name ? (
                          <>
                            <span>{ws.data.template_name}</span>
                            <span className="ml-1">{ws.data.template_option_name}</span>
                          </>
                        ) : (
                          `${ws.data.gameType} ${ws.data.bestOf}`
                        )}
                      </span>
                    </div>

                    <div className="qc-session__header-first">
                      <div className="qc-session__header-first-map">
                        <span className="qc-session__header-first-desc">First for maps </span>
                        <span className="qc-session__header-first-value">{ws.data.firstMap}</span>
                      </div>
                      <div className="qc-session__header-first-map">
                        <span className="qc-session__header-first-desc">First for champions </span>
                        <span className="qc-session__header-first-value">{ws.data.firstChampion}</span>
                      </div>
                    </div>

                    <div className="qc-session__header-page-for">
                      Page for
                      <span className="qc-session__header-page-for-player">
                        {ws.data[ws.data.role] ? ws.data[ws.data.role] : ws.data.role}
                      </span>
                    </div>
                    <div className="qc-session__header-links">
                      {ws.data.player_code && ws.data.role !== 'player1' && (
                        <Button
                          classes="mr-2"
                          text={`Link for ${ws.data.player1}`}
                          color="orange"
                          iconLeft={faCopy}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `https://${window.location.hostname}/session/${ws.data!.player_code}/${encodeURIComponent(
                                ws.data!.player1
                              )}`
                            )
                          }}
                        />
                      )}
                      {ws.data.player_code && ws.data.role !== 'player2' && (
                        <Button
                          classes="mr-2"
                          text={`Link for ${ws.data.player2}`}
                          color="orange"
                          iconLeft={faCopy}
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `https://${window.location.hostname}/session/${ws.data!.player_code}/${encodeURIComponent(
                                ws.data!.player2
                              )}`
                            )
                          }}
                        />
                      )}
                      {!ws.data.player_code && ws.data.spectator_code && ws.data.template_bracket_link && <BracketButton link={ws.data.template_bracket_link} />}
                      {!ws.data.player_code && ws.data.spectator_code && ws.data.streams && ws.data.streams.length > 0 && <StreamsMenu streams={ws.data.streams} />}
                      {ws.data.spectator_code && (
                          <Button
                            text={`Link for spectator`}
                            color="orange"
                            iconLeft={faCopy}
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `https://${window.location.hostname}/session/${ws.data!.spectator_code}`
                              )
                            }}
                          />
                      )}
                    </div>
                  </div>
                </div>
                <div className="qc-session__body">
                  <div className="qc-session__body-container">
                    <div className="maps">
                      <h1>Maps</h1>
                      <div className="d-flex flex-column">
                        {
                          <DoneStepsInfo
                            steps={steps.filter((step: IStep) => step.what === 'map' && step.name !== null)}
                          />
                        }
                        {nextStep !== null && nextStep && nextStep.what === 'map' && (
                          <NextStepInfo {...nextStepProps} />
                        )}
                      </div>
                    </div>
                    <div className={'champions'}>
                      {(nextStep === null || (nextStep && nextStep.what === 'champion')) && (
                        <>
                          <h1>Champions</h1>
                          <div className="champions-container d-flex flex-column">
                            <>
                              {ws.data.model.champion && (
                                <>
                                  <PickBanInfoForMap title="All maps">
                                    <DoneStepsInfo
                                      steps={steps.filter(
                                        (step: IStep) =>
                                          step.what === 'champion' && step.name !== null && step.map === undefined
                                      )}
                                    />
                                    {nextStep !== null &&
                                      nextStep &&
                                      nextStep.what === 'champion' &&
                                      nextStep.map === undefined && <NextStepInfo {...nextStepProps} />}
                                  </PickBanInfoForMap>
                                </>
                              )}
                              {stepMapList.map((map) => {
                                if (
                                  steps.filter((step: IStep) => step.map === map && step.name !== null).length > 0 ||
                                  (nextStep !== null &&
                                    nextStep &&
                                    nextStep.what === 'champion' &&
                                    nextStep.map === map)
                                ) {
                                  return (
                                    <PickBanInfoForMap title={map}>
                                      {
                                        <DoneStepsInfo
                                          steps={steps.filter((step: IStep) => step.map === map && step.name !== null)}
                                        />
                                      }
                                      {nextStep !== null &&
                                        nextStep &&
                                        nextStep.what === 'champion' &&
                                        nextStep.map === map && <NextStepInfo {...nextStepProps} />}
                                    </PickBanInfoForMap>
                                  )
                                }
                              })}
                            </>
                          </div>
                        </>
                      )}
                    </div>
                    {nextStep === null ? (
                      <div className="results">
                        <h1>Results</h1>
                        <PickBanResultsInfo
                          player1={ws.data.player1}
                          player2={ws.data.player2}
                          steps={steps}
                          gameType={ws.data.gameType}
                          bestOf={ws.data.bestOf}
                          stepMapList={stepMapList}
                        />
                      </div>
                    ) : (
                      <div className="pools">
                        <h1>Pools</h1>
                        {nextStep && nextStep.what === 'champion' && (
                          <div className="champion-pools">
                            {ws.data.champions.length > 0 && (
                              <PoolInfoContainer type={PoolType.Champions} status={PoolStatus.Available}>
                                {ws.data.champions.map((x) => {
                                  return (
                                    <PoolInfo type={PoolType.Champions} status={PoolStatus.Available} name={x.name} />
                                  )
                                })}
                              </PoolInfoContainer>
                            )}
                            {steps.filter(
                              (step: IStep) => step.what === 'champion' && step.does == 'pick' && step.name !== null
                            ).length > 0 && (
                              <PoolInfoContainer type={PoolType.Champions} status={PoolStatus.Picked}>
                                {steps
                                  .filter(
                                    (step: IStep) =>
                                      step.what === 'champion' && step.does == 'pick' && step.name !== null
                                  )
                                  .map((x) => {
                                    return (
                                      <PoolInfo type={PoolType.Champions} status={PoolStatus.Picked} name={x.name} />
                                    )
                                  })}
                              </PoolInfoContainer>
                            )}
                            {steps.filter(
                              (step: IStep) => step.what === 'champion' && step.does == 'ban' && step.name !== null
                            ).length > 0 && (
                              <PoolInfoContainer type={PoolType.Champions} status={PoolStatus.Banned}>
                                {steps
                                  .filter(
                                    (step: IStep) =>
                                      step.what === 'champion' && step.does == 'ban' && step.name !== null
                                  )
                                  .map((x) => {
                                    return (
                                      <PoolInfo type={PoolType.Champions} status={PoolStatus.Banned} name={x.name} />
                                    )
                                  })}
                              </PoolInfoContainer>
                            )}
                          </div>
                        )}
                        {nextStep && nextStep.what === 'map' && (
                          <div className="map-pools">
                            {ws.data.maps.length > 0 && (
                              <PoolInfoContainer type={PoolType.Maps} status={PoolStatus.Available}>
                                {ws.data.maps.map((x) => {
                                  return <PoolInfo type={PoolType.Maps} status={PoolStatus.Available} name={x.name} />
                                })}
                              </PoolInfoContainer>
                            )}
                            {steps.filter(
                              (step: IStep) => step.what === 'map' && step.does == 'won' && step.name !== null
                            ).length > 0 && (
                              <PoolInfoContainer type={PoolType.Maps} status={PoolStatus.Won}>
                                {steps
                                  .filter(
                                    (step: IStep) => step.what === 'map' && step.does == 'won' && step.name !== null
                                  )
                                  .map((x) => {
                                    return <PoolInfo type={PoolType.Maps} status={PoolStatus.Won} name={x.name} />
                                  })}
                              </PoolInfoContainer>
                            )}
                            {steps.filter(
                              (step: IStep) => step.what === 'map' && step.does == 'pick' && step.name !== null
                            ).length > 0 && (
                              <PoolInfoContainer type={PoolType.Maps} status={PoolStatus.Picked}>
                                {steps
                                  .filter(
                                    (step: IStep) => step.what === 'map' && step.does == 'pick' && step.name !== null
                                  )
                                  .map((x) => {
                                    return <PoolInfo type={PoolType.Maps} status={PoolStatus.Picked} name={x.name} />
                                  })}
                              </PoolInfoContainer>
                            )}
                            {steps.filter(
                              (step: IStep) => step.what === 'map' && step.does == 'ban' && step.name !== null
                            ).length > 0 && (
                              <PoolInfoContainer type={PoolType.Maps} status={PoolStatus.Banned}>
                                {steps
                                  .filter(
                                    (step: IStep) => step.what === 'map' && step.does == 'ban' && step.name !== null
                                  )
                                  .map((x) => {
                                    return <PoolInfo type={PoolType.Maps} status={PoolStatus.Banned} name={x.name} />
                                  })}
                              </PoolInfoContainer>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </CSSTransition>
      }
      {ws.data && ws.data.error && (
        <Alert title={ws.data.error} description={ws.data.message} color="red" classes="m-3" />
      )}
    </>
  )
}
