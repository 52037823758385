import { CSSProperties } from 'react'
import { IValidation } from '../../validator/validation.models'
import { IData } from '../../components/component.models'

export enum DataListTypes {
  Default = 'default',
  Grid = 'grid',
}

export interface ICssProps extends CSSProperties {
  '--col-count'?: number
}

export interface IProps {
  classes?: string
  css?: ICssProps
  data: IData[]
  dataLoading?: boolean
  dataLoadError?: string
  keyField?: string
  valueField?: string
  selectedData: IData[]
  multiselect?: boolean
  showSelected?: boolean
  type?: DataListTypes
  validation?: IValidation
  isValid?: boolean
  errors?: string[]
  color?: string
  columnCount?: number | null
  disabled?: boolean
  emptyValue?: string | null
  //
  onSelectedDataChange: (data: IData[]) => void
}

export const defaultProps: IProps = {
  data: [],
  keyField: 'id',
  valueField: 'value',
  selectedData: [],
  onSelectedDataChange: () => void 0,
  multiselect: false,
  showSelected: false,
  type: DataListTypes.Default,
  color: 'green',
  css: {},
  columnCount: null,
  disabled: false,
  emptyValue: null,
}

export function mergePropsWithDefault(
  props: IProps,
  defaults: IProps = defaultProps
): IProps {
  const cssProps = { ...defaults.css, ...props.css }
  return {
    ...defaults,
    ...props,
    css: cssProps,
  } as IProps
}
