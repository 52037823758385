import React, { CSSProperties } from 'react'

export enum DropdownFrom {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
}

export interface ICSSProps extends CSSProperties {
  '--top'?: string
  '--left'?: string
  '--translate'?: string
  '--transition-delay-ms'?: string
}

export interface IProps {
  classes?: string
  css?: ICSSProps
  children?: React.ReactNode
  opened: boolean
  onOpenedChange: (opened: boolean) => void
  from?: DropdownFrom
  disableBackdrop?: boolean
  disableOpenAnimation?: boolean
  disableCloseAnimation?: boolean
}

export const defaultProps: IProps = {
  opened: false,
  onOpenedChange: () => void 0,
  from: DropdownFrom.Right,
  disableOpenAnimation: false,
  disableCloseAnimation: false,
}

export function mergePropsWithDefault(props: IProps, defaults: IProps = defaultProps): IProps {
  const cssProps = { ...defaults.css, ...props.css }
  return {
    ...defaults,
    ...props,
    css: cssProps,
  } as IProps
}
