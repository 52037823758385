import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight as ArrowIcon } from '@fortawesome/free-solid-svg-icons'
import { CSSProperties } from 'react'
import { IData } from '../component.models'
import {
  IProps as IDataListProps,
  mergePropsWithDefault as mergeDataListPropsWithDefault,
  defaultProps as dataListDefaults,
} from '../../componentsKit/data-list/data-list.models'
import {
  IProps as IDropdownProps,
  mergePropsWithDefault as mergeDropdownPropsWithDefault,
} from '../../componentsKit/dropdown/dropdown.models'
import { openIn } from '../../componentsKit/select/select.models'

export interface IProps {
  classes?: string
  css?: CSSProperties
  template: IDataListProps
  templateOption: IDataListProps
  dropdown?: IDropdownProps
  caption?: string
  arrowIcon?: IconDefinition
  color?: string
  maps: IData[]
  champions: IData[]
  openIn?: openIn
  onOpen?: () => void
  onClose?: () => void
}

export const defaultProps: IProps = {
  template: {
    data: [] as IData[],
    selectedData: [] as IData[],
    onSelectedDataChange: (data: IData[]) => void 0,
    multiselect: false,
    showSelected: true,
  },
  templateOption: {
    data: [] as IData[],
    selectedData: [] as IData[],
    onSelectedDataChange: (data: IData[]) => void 0,
    multiselect: false,
    showSelected: true,
  },
  dropdown: {
    opened: false,
    onOpenedChange: () => void 0,
    classes: 'd-flex',
  },
  arrowIcon: ArrowIcon,
  color: 'green',
  maps: [],
  champions: [],
  openIn: openIn.Dropdown,
}

export function mergePropsWithDefault(
  props: IProps,
  defaults: IProps = defaultProps
): IProps {
  const cssProps = { ...defaults.css, ...props.css }
  let gameTypeProps = mergeDataListPropsWithDefault(
    defaults.template,
    dataListDefaults
  )
  gameTypeProps = mergeDataListPropsWithDefault(props.template, gameTypeProps)
  //
  let bestOfProps = mergeDataListPropsWithDefault(
    defaults.templateOption,
    dataListDefaults
  )
  bestOfProps = mergeDataListPropsWithDefault(props.templateOption, bestOfProps)
  //
  const dropdownProps = props.dropdown
    ? mergeDropdownPropsWithDefault(props.dropdown, defaults.dropdown)
    : defaults.dropdown

  return {
    ...defaults,
    ...props,
    template: gameTypeProps,
    templateOption: bestOfProps,
    dropdown: dropdownProps,
    css: cssProps,
  } as IProps
}
