import { CSSProperties } from 'react'
import { IData } from '../../components/component.models'

export interface IItemProps {
  classes?: string
  css?: CSSProperties
  dataItem?: IData
  checked?: boolean
  onCheckedItemChange?(item: IData): void
  keyField?: string
  valueField?: string
  disabled?: boolean
}

export interface IProps {
  classes?: string
  css?: CSSProperties
  data: IData[]
  onCheckedItemChange: (data: IData) => void
  keyField?: string
  valueField?: string
  itemProps?: { [key: number]: IItemProps }
  checkedItem: IData | null
  disabled?: boolean
  caption?: string
}

export const defaultProps: IProps = {
  data: [],
  checkedItem: [],
  onCheckedItemChange: () => void 0,
  keyField: 'id',
  valueField: 'value',
  disabled: false,
  itemProps: {},
}

export const defaultItemProps: IItemProps = {
  checked: false,
  onCheckedItemChange: () => void 0,
}

export function mergePropsWithDefault(
  props: IProps,
  defaults: IProps = defaultProps
): IProps {
  const cssProps = { ...defaults.css, ...props.css }
  return {
    ...defaults,
    ...props,
    css: cssProps,
  } as IProps
}

export function mergeItemPropsWithDefault(
  props: IItemProps,
  defaults: IItemProps = defaultItemProps
): IItemProps {
  const cssProps = { ...defaults.css, ...props.css }
  return {
    ...defaults,
    ...props,
    css: cssProps,
  } as IItemProps
}
