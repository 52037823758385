import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Alert } from '../componentsKit/alert/Alert';
import { Loader } from '../componentsKit/loader/Loader';
import { ITemplate, ITemplateOption, useMyTemplate } from '../hooks/my-template.hook';
import { IProps as ITextEditProps } from '../componentsKit/text-edit/text-edit.models';
import { DataListTypes } from '../componentsKit/data-list/data-list.models';
import { IProps as ITemplateOptionsEditorProps } from '../components/template-options-editor/template-options-editor.models';
import '../scss/my-template-edit.scss';
import { TextEdit } from '../componentsKit/text-edit/TextEdit';
import { Button } from '../componentsKit/button/Button';
import { useHttp } from '../hooks/http.hook';
import { AuthContext } from '../context/AuthContext';
import { IData } from '../components/component.models';
import { TemplateOptionsEditor } from '../components/template-options-editor/TemplateOptionsEditor';
import { IBestOfChampionAlgo, IBestOfMapAlgo } from '../hooks/dataForCreateSession.hook';
import { IProps as ISelectGameTypeFull } from '../components/select-gametype-full/select-gametype-full.models';
import { openIn, IProps as ISelectProps } from '../componentsKit/select/select.models';
import { SelectGameTypeFull } from '../components/select-gametype-full/SelectGameTypeFull';
import { Select } from '../componentsKit/select/Select';
import { Validation } from '../validator/validation';
import { ValidationStart } from '../validator/validation.models';
import { DropdownFrom } from '../componentsKit/dropdown/dropdown.models';
import { faChevronDown as ArrowIcon } from '@fortawesome/free-solid-svg-icons';
import { IProps as IRadioProps } from '../componentsKit/radio/radio.models';
import { Radio } from '../componentsKit/radio/Radio';

export const MyTemplateEditPage = () => {
  //
  const openInModalWidth = 1339;
  const isPrivateData = [
    { id: 1, value: 'Just me' },
    { id: 2, value: 'All' },
  ];
  // params
  const { id } = useParams();
  // context
  const { token } = useContext(AuthContext);
  // data from server
  const { myTemplateData, loading, error } = useMyTemplate(useParams().id!);
  const [dataReady, setDataReady] = useState(false);
  // hooks - navigate
  const navigate = useNavigate();
  // hooks - custom
  const { request } = useHttp();
  // hooks - states
  const [templateNameEditValue, setTemplateNameEditvalue] = useState('');
  const [templateOptionsSelectedData, setTemplateOptionsSelectedData] = useState<IData[]>([]);
  const [optionNameEditValue, setOptionNameEditValue] = useState<string | null>(null);
  const [gameTypeSelectedData, setGameTypeSelectedData] = useState<IData[]>([]);
  const [mapAlgoSelectedData, setMapAlgoSelectedData] = useState<IData[]>([]);
  const [championAlgoSelectedData, setChampionAlgoSelectedData] = useState<IData[]>([]);
  const [firstMapSelectedData, setFirstMapSelectedData] = useState<IData[]>([]);
  const [firstChampionSelectedData, setFirstChampionSelectedData] = useState<IData[]>([]);

  const [defaultTemplateOptionSelectedData, setDefaultTemplateOptionSelectedData] = useState<IData[]>([]);
  const [defaultTemplateOptionSelectOpened, setDefaultTemplateOptionSelectOpened] = useState(false);

  const [bestOfSelectedData, setBestOfSelectedData] = useState<IData[]>([]);
  const [gameTypeSelectOpened, setGameTypeSelectOpened] = useState(false);
  const [championSelectedData, setChampionSelectedData] = useState<IData[]>([]);
  const [championSelectOpened, setChampionSelectOpened] = useState(false);
  const [mapSelectedData, setMapSelectedData] = useState<IData[]>([]);
  const [mapSelectOpened, setMapSelectOpened] = useState(false);
  const [minMaps, setMinMaps] = useState(0);
  const [minChampions, setMinChampions] = useState(0);
  const [optionSortParam, setOptionSortParam] = useState<number | null>(null);
  const [isPrivateTemplateValue, setIsPrivateTemplateValue] = useState<IData | null>(null);
  // hooks - states - validation
  const [canValidateTemplateName, setCanValidateTemplateName] = useState(false);
  const [canValidateTemplateOptionName, setCanValidateTemplateOptionName] = useState(false);
  const [canValidateMaps, setCanValidateMaps] = useState(false);
  const [canValidateChampions, setCanValidateChampions] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  // hooks - effects
  useEffect(() => {
    if (!loading && !error && myTemplateData) {
      if (myTemplateData.template === null) {
        createTemplate();
        createTemplateOption();
      }
      setTemplateNameEditvalue(myTemplateData.template.name);
      const findTemplateDefaultOption = myTemplateData.template.templateOption.find((x) => x.is_default === true);
      if (findTemplateDefaultOption) {
        setTemplateOptionsSelectedData([findTemplateDefaultOption]);
        setDefaultTemplateOptionSelectedData([findTemplateDefaultOption]);
      }
      setIsPrivateTemplateValue(myTemplateData.template.is_private ? isPrivateData[0] : isPrivateData[1]);
      setDataReady(true);
      setCanValidateTemplateName(true);
    }
  }, [myTemplateData]);
  useEffect(() => {
    if (templateOptionsSelectedData.length > 0) {
      setOptionNameEditValue(templateOptionsSelectedData[0].name);
      setChampionSelectedData(myTemplateData!.data.champion.filter((x) => templateOptionsSelectedData[0].champions.includes(x.id)));
      setMapSelectedData(myTemplateData!.data.map.filter((x) => templateOptionsSelectedData[0].maps.includes(x.id)));
      setGameTypeSelectedData(myTemplateData!.data.gameType.filter((x) => x.id === templateOptionsSelectedData[0].game_typeID));
      setBestOfSelectedData(myTemplateData!.data.bestOf.filter((x) => x.id === templateOptionsSelectedData[0].best_ofID));
      setMapAlgoSelectedData(myTemplateData!.data.bestOfMapAlgo.filter((x) => x.map_algoID === templateOptionsSelectedData[0].map_algoID));
      setChampionAlgoSelectedData(myTemplateData!.data.bestOfChampionAlgo.filter((x) => x.champion_algoID === templateOptionsSelectedData[0].champion_algoID));
      setFirstMapSelectedData(myTemplateData!.data.first.filter((x) => x.id === templateOptionsSelectedData[0].first_mapID));
      setFirstChampionSelectedData(myTemplateData!.data.first.filter((x) => x.id === templateOptionsSelectedData[0].first_championID));
      setOptionSortParam(templateOptionsSelectedData[0].sort_param);
    }
  }, [templateOptionsSelectedData]);
  useEffect(() => {
    if (dataReady && bestOfSelectedData.length > 0 && gameTypeSelectedData.length > 0 && mapAlgoSelectedData.length > 0) {
      const findMinMaps = myTemplateData!.data.bestOfMapAlgo.find(
        (x) => x.best_ofID === bestOfSelectedData[0].id && x.game_typeID === gameTypeSelectedData[0].id && x.map_algoID === mapAlgoSelectedData[0].map_algoID
      );
      if (findMinMaps) {
        setCanValidateMaps(true);
        setMinMaps(findMinMaps.min_maps);
      }
    }
  }, [bestOfSelectedData, gameTypeSelectedData, mapAlgoSelectedData]);
  useEffect(() => {
    if (dataReady && bestOfSelectedData.length > 0 && gameTypeSelectedData.length > 0 && championAlgoSelectedData.length > 0) {
      const findMinChampions = myTemplateData!.data.bestOfChampionAlgo.find(
        (x) =>
          x.best_ofID === bestOfSelectedData[0].id &&
          x.game_typeID === gameTypeSelectedData[0].id &&
          x.champion_algoID === championAlgoSelectedData[0].champion_algoID
      );
      if (findMinChampions) {
        setCanValidateChampions(true);
        setMinChampions(findMinChampions.min_champions);
      }
    }
  }, [bestOfSelectedData, gameTypeSelectedData, championAlgoSelectedData]);
  // handlers
  const regExp = new RegExp(/^[\w0-9А-я ]+$/, 'g');
  const changeTemplateNameHandler = (value: string) => {
    if (value.length === 0 || regExp.test(value)) {
      setTemplateNameEditvalue(value);
      myTemplateData!.template.name = value;
    }
  };
  const changeSelectedTemplateOptionHandler = (value: IData[]) => {
    setTemplateOptionsSelectedData(value);
  };
  const changeOptionNameHandler = (value: string) => {
    if (value.length === 0 || regExp.test(value)) {
      setOptionNameEditValue(value);
      const findTemplateOption = myTemplateData!.template!.templateOption.find((x) => x.id === templateOptionsSelectedData[0].id);
      if (findTemplateOption) {
        findTemplateOption.name = value;
      }
      setCanValidateTemplateOptionName(true);
    }
  };
  const selectGameTypeHandler = (data: IData[]) => {
    setMapAlgoSelectedData([]);
    setChampionAlgoSelectedData([]);
    setGameTypeSelectedData(data);
    const findTemplateOption = myTemplateData!.template!.templateOption.find((x) => x.id === templateOptionsSelectedData[0].id);
    if (findTemplateOption) {
      findTemplateOption.game_typeID = data[0].id;
    }
    setDefaultAlgo(data[0].id, bestOfSelectedData[0].id);
  };
  const selectBestOfHandler = (data: IData[]) => {
    setMapAlgoSelectedData([]);
    setChampionAlgoSelectedData([]);
    setBestOfSelectedData(data);
    const findTemplateOption = myTemplateData!.template!.templateOption.find((x) => x.id === templateOptionsSelectedData[0].id);
    if (findTemplateOption) {
      findTemplateOption.best_ofID = data[0].id;
    }
    setDefaultAlgo(gameTypeSelectedData[0].id, data[0].id);
  };
  const selectMapAlgoHandler = (data: IData[]) => {
    setMapAlgoSelectedData(data);
    const findTemplateOption = myTemplateData!.template!.templateOption.find((x) => x.id === templateOptionsSelectedData[0].id);
    if (findTemplateOption) {
      findTemplateOption.map_algoID = data[0].map_algoID;
    }
  };
  const selectChampionAlgoHandler = (data: IData[]) => {
    setChampionAlgoSelectedData(data);
    const findTemplateOption = myTemplateData!.template!.templateOption.find((x) => x.id === templateOptionsSelectedData[0].id);
    if (findTemplateOption) {
      findTemplateOption.champion_algoID = data[0].champion_algoID;
    }
  };
  const selectChampionHandler = (data: IData[]) => {
    setChampionSelectedData(data);
    const findTemplateOption = myTemplateData!.template!.templateOption.find((x) => x.id === templateOptionsSelectedData[0].id);
    if (findTemplateOption) {
      findTemplateOption.champions = data.map((x) => x.id);
    }
  };
  const selectMapHandler = (data: IData[]) => {
    setMapSelectedData(data);
    const findTemplateOption = myTemplateData!.template!.templateOption.find((x) => x.id === templateOptionsSelectedData[0].id);
    if (findTemplateOption) {
      findTemplateOption.maps = data.map((x) => x.id);
    }
  };
  const selectFirstMapHandler = (data: IData[]) => {
    setFirstMapSelectedData(data);
    const findTemplateOption = myTemplateData!.template!.templateOption.find((x) => x.id === templateOptionsSelectedData[0].id);
    if (findTemplateOption) {
      findTemplateOption.first_mapID = data[0].id;
    }
  };
  const selectFirstChampionHandler = (data: IData[]) => {
    setFirstChampionSelectedData(data);
    const findTemplateOption = myTemplateData!.template!.templateOption.find((x) => x.id === templateOptionsSelectedData[0].id);
    if (findTemplateOption) {
      findTemplateOption.first_championID = data[0].id;
    }
  };
  const addNewTemplateOption = () => {
    createTemplateOption();
    setTemplateOptionsSelectedData([myTemplateData!.template.templateOption[myTemplateData!.template.templateOption.length - 1]]);
  };
  const selectDefaultTemplateOptiontHandler = (data: IData[]) => {
    selectDefaultTemplateOption(data);
  };
  const saveChangesHandler = async () => {
    if (dataReady) {
      if (!canValidateTemplateName) {
        setCanValidateTemplateName(true);
      }
      if (!canValidateTemplateOptionName) {
        setCanValidateTemplateOptionName(true);
      }
      if (!canValidateMaps) {
        setCanValidateMaps(true);
      }
      if (!canValidateChampions) {
        setCanValidateChampions(true);
      }
      if (
        textEditTemplateNameProps.isValid &&
        textEditTemplateOptionNameProps.isValid &&
        selectMapsProps.dataList.isValid &&
        selectChampionProps.dataList.isValid
      ) {
        setButtonDisabled(true);

        console.log(isPrivateTemplateValue);

        const template = myTemplateData!.template;
        try {
          const body = {
            id: template.id,
            name: template.name,
            is_private: isPrivateTemplateValue?.id === isPrivateData[0].id ? true : false,
            templateOptions: template.templateOption.map((x) => {
              return {
                id: x.id,
                name: x.name,
                game_typeID: x.game_typeID,
                best_ofID: x.best_ofID,
                map_algoID: x.map_algoID,
                champion_algoID: x.champion_algoID,
                maps: x.maps.sort((a, b) => a - b),
                champions: x.champions.sort((a, b) => a - b),
                first_mapID: x.first_mapID,
                first_championID: x.first_championID,
                sort_param: x.sort_param,
                is_default: x.is_default,
              };
            }),
          };
          const req = await request('/my-templates/saveMyTemplate', 'POST', body, {
            Authorization: `Bearer ${token}`,
          });
          if (req.error) {
            setButtonDisabled(false);
            console.error(`${req.error}: ${req.message}`);
          } else {
            navigate('/my-templates');
          }
        } catch (e) {}
      }
    }
  };
  const cancelHandler = () => {
    navigate('/my-templates');
  };
  const privateChangeHandler = (data: IData) => {
    setIsPrivateTemplateValue(data);
  };
  // methods
  const resortTemplateOptions = (sort_param: number = 0) => {
    if (myTemplateData) {
      for (const templateOption of myTemplateData.template.templateOption.sort((a, b) => a.sort_param - b.sort_param)) {
        templateOption.sort_param = sort_param;
        sort_param++;
      }
    }
  };
  const setDefaultAlgo = (gameTypeID: number, bestOfID: number) => {
    const findTemplateOption = myTemplateData!.template!.templateOption.find((x) => x.id === templateOptionsSelectedData[0].id);
    //
    const findMapAlgo: IBestOfMapAlgo | undefined = myTemplateData!.data.bestOfMapAlgo.find(
      (x) => x.best_ofID === bestOfID && x.game_typeID === gameTypeID && x.is_default === true
    );
    if (findMapAlgo) {
      setMapAlgoSelectedData([findMapAlgo]);
      if (findTemplateOption) {
        findTemplateOption.map_algoID = findMapAlgo.map_algoID;
      }
    }
    const findChampionAlgo: IBestOfChampionAlgo | undefined = myTemplateData!.data.bestOfChampionAlgo.find(
      (x) => x.best_ofID === bestOfID && x.game_typeID === gameTypeID && x.is_default === true
    );
    if (findChampionAlgo) {
      setChampionAlgoSelectedData([findChampionAlgo]);
      if (findTemplateOption) {
        findTemplateOption.champion_algoID = findChampionAlgo.champion_algoID;
      }
    }
  };
  const createTemplate = () => {
    if (myTemplateData) {
      const newTemplate: ITemplate = {
        id: 0,
        name: 'NewTemplate',
        is_default: false,
        sort_param: 0,
        is_private: true,
        templateOption: [],
      };
      myTemplateData.template = newTemplate;
    }
  };
  const createTemplateOption = () => {
    if (myTemplateData && myTemplateData.template && myTemplateData.template.templateOption.length < 10) {
      const defaultBestOf = myTemplateData.data.bestOf.find((x) => x.is_default === true);
      const defaultGameType = myTemplateData.data.gameType.find((x) => x.is_default === true);
      const defaultMapAlgo =
        defaultBestOf && defaultGameType
          ? myTemplateData.data.bestOfMapAlgo.find((x) => x.is_default === true && x.best_ofID === defaultBestOf.id && x.game_typeID === defaultGameType.id)
          : null;
      const defaultChampionAlgo =
        defaultBestOf && defaultGameType
          ? myTemplateData.data.bestOfChampionAlgo.find(
              (x) => x.is_default === true && x.best_ofID === defaultBestOf.id && x.game_typeID === defaultGameType.id
            )
          : null;
      const defaultFirstMap = myTemplateData.data.first.find((x) => x.is_default === true && x.type === 'map');
      const defaultFirstChampion = myTemplateData.data.first.find((x) => x.is_default === true && x.type === 'champion');

      const templateOptionMinId = myTemplateData.template.templateOption.reduce((acc, cur) => Math.min(acc, cur.id), 0);
      const newTemplateOption: ITemplateOption = {
        id: templateOptionMinId < 0 ? templateOptionMinId - 1 : -1,
        best_ofID: defaultBestOf ? defaultBestOf!.id : 1,
        game_typeID: defaultGameType ? defaultGameType.id : 9,
        map_algoID: defaultMapAlgo ? defaultMapAlgo.map_algoID : 1,
        champion_algoID: defaultChampionAlgo ? defaultChampionAlgo.champion_algoID : 1,
        first_mapID: defaultFirstMap ? defaultFirstMap.id : 1,
        first_championID: defaultFirstChampion ? defaultFirstChampion.id : 8,
        maps: myTemplateData.data.map.filter((x) => x.is_default === true).map((x) => x.id),
        champions: myTemplateData.data.champion.filter((x) => x.is_default === true).map((x) => x.id),
        name: 'NewOption',
        is_default: myTemplateData.template.templateOption.find((x) => x.is_default === true) ? false : true,
        sort_param: 0,
        templateID: myTemplateData.template.id,
      };
      resortTemplateOptions(1);
      myTemplateData.template.templateOption.push(newTemplateOption);
    }
  };
  const deleteTemplateOption = () => {
    if (myTemplateData && myTemplateData.template && myTemplateData.template.templateOption.length > 1) {
      const templateOptionId = templateOptionsSelectedData[0].id;
      //
      if (templateOptionsSelectedData[0].is_default === true) {
        const firstNoDefaultOption = myTemplateData.template!.templateOption!.filter((x) => x.id !== templateOptionId && x.is_default === false);
        if (firstNoDefaultOption) {
          selectDefaultTemplateOption(firstNoDefaultOption);
        }
      }
      //
      setTemplateOptionsSelectedData([]);
      myTemplateData.template.templateOption = myTemplateData.template.templateOption.filter((x) => x.id !== templateOptionId);
      //
      resortTemplateOptions();
      //
      setTemplateOptionsSelectedData([myTemplateData.template.templateOption.sort((a, b) => b.sort_param - a.sort_param)[0]]);
    }
  };
  const upTemplateOption = () => {
    if (myTemplateData && myTemplateData.template) {
      const upTempleteOption = myTemplateData.template.templateOption.find((x) => x.sort_param === templateOptionsSelectedData[0].sort_param + 1);
      if (upTempleteOption) {
        upTempleteOption.sort_param--;
        templateOptionsSelectedData[0].sort_param++;
        setOptionSortParam(templateOptionsSelectedData[0].sort_param);
      }
    }
  };
  const downTemplateOption = () => {
    if (myTemplateData && myTemplateData.template) {
      const downTempleteOption = myTemplateData.template.templateOption.find((x) => x.sort_param === templateOptionsSelectedData[0].sort_param - 1);
      if (downTempleteOption) {
        downTempleteOption.sort_param++;
        templateOptionsSelectedData[0].sort_param--;
        setOptionSortParam(templateOptionsSelectedData[0].sort_param);
      }
    }
  };
  const selectDefaultTemplateOption = (data: IData[]) => {
    setDefaultTemplateOptionSelectedData(data);
    myTemplateData!.template!.templateOption.forEach((x) => {
      x.is_default = false;
    });
    const findTemplateOption = myTemplateData!.template!.templateOption.find((x) => x.id === data[0].id);
    if (findTemplateOption) {
      findTemplateOption.is_default = true;
    }
  };
  // props
  const templateNameValidation = new Validation({
    checks: [
      {
        isValid: templateNameEditValue.trim().length > 0,
        error: 'Please enter template name',
      },
    ],
    canValidate: canValidateTemplateName,
    setCanValidate: setCanValidateTemplateName,
  });
  const textEditTemplateNameProps: ITextEditProps = {
    editValue: templateNameEditValue,
    onEditValueChange: changeTemplateNameHandler,
    caption: 'Template name',
    maxLength: 25,
    validation: templateNameValidation.props,
    errors: templateNameValidation.errors,
    isValid: templateNameValidation.isValid,
  };
  const templateOptionNameValidation = new Validation({
    checks: [
      {
        isValid: optionNameEditValue ? optionNameEditValue.trim().length > 0 : false,
        error: 'Please enter template option name',
      },
    ],
    canValidate: canValidateTemplateOptionName,
    setCanValidate: setCanValidateTemplateOptionName,
  });
  const textEditTemplateOptionNameProps: ITextEditProps = {
    editValue: optionNameEditValue ? optionNameEditValue : '',
    onEditValueChange: changeOptionNameHandler,
    caption: 'Option name',
    maxLength: 25,
    validation: templateOptionNameValidation.props,
    errors: templateOptionNameValidation.errors,
    isValid: templateOptionNameValidation.isValid,
  };
  const selectGameTypeFullProps: ISelectGameTypeFull = {
    gameType: {
      data: myTemplateData?.data?.gameType || [],
      dataLoading: loading,
      dataLoadError: error,
      selectedData: gameTypeSelectedData,
      onSelectedDataChange: selectGameTypeHandler,
      valueField: 'name',
    },
    bestOf: {
      data: myTemplateData?.data?.bestOf || [],
      dataLoading: loading,
      dataLoadError: error,
      selectedData: bestOfSelectedData,
      onSelectedDataChange: selectBestOfHandler,
      valueField: 'name',
    },
    mapAlgo: {
      data:
        dataReady && bestOfSelectedData.length > 0 && gameTypeSelectedData.length > 0
          ? myTemplateData!.data.bestOfMapAlgo.filter((x) => x.best_ofID === bestOfSelectedData[0].id && x.game_typeID === gameTypeSelectedData[0].id)
          : [],
      dataLoading: loading,
      dataLoadError: error,
      selectedData: mapAlgoSelectedData,
      onSelectedDataChange: selectMapAlgoHandler,
      keyField: 'map_algoID',
      valueField: 'name',
    },
    championAlgo: {
      data:
        dataReady && bestOfSelectedData.length > 0 && gameTypeSelectedData.length > 0
          ? myTemplateData!.data!.bestOfChampionAlgo.filter((x) => x.best_ofID === bestOfSelectedData[0].id && x.game_typeID === gameTypeSelectedData[0].id)
          : [],
      dataLoading: loading,
      dataLoadError: error,
      selectedData: championAlgoSelectedData,
      onSelectedDataChange: selectChampionAlgoHandler,
      keyField: 'champion_algoID',
      valueField: 'name',
    },
    firstMap: {
      data: myTemplateData?.data?.first.filter((x) => x.type === 'map') || [],
      dataLoading: loading,
      dataLoadError: error,
      selectedData: firstMapSelectedData,
      onSelectedDataChange: selectFirstMapHandler,
      valueField: 'name',
    },
    firstChampion: {
      data: myTemplateData?.data?.first.filter((x) => x.type === 'champion') || [],
      dataLoading: loading,
      dataLoadError: error,
      selectedData: firstChampionSelectedData,
      onSelectedDataChange: selectFirstChampionHandler,
      valueField: 'name',
    },
    dropdown: {
      opened: gameTypeSelectOpened,
      onOpenedChange: setGameTypeSelectOpened,
      css: {
        '--left': '290px',
        '--top': '-76px',
      },
    },
    caption: 'Game type',
    mapCount: mapSelectedData.length,
    championCount: championSelectedData.length,
    bestOfMapAlgo: myTemplateData?.data?.bestOfMapAlgo || [],
    bestOfChampionAlgo: myTemplateData?.data?.bestOfChampionAlgo || [],
    selectedBestOf: bestOfSelectedData,
    selectedGameType: gameTypeSelectedData,
    openIn: window.innerWidth >= openInModalWidth ? openIn.Dropdown : openIn.Modal,
  };
  const championsValidation = new Validation({
    checks: [
      {
        isValid: gameTypeSelectedData.length > 0 && bestOfSelectedData.length > 0 ? championSelectedData.length >= minChampions : false,
        error:
          gameTypeSelectedData.length > 0 && bestOfSelectedData.length > 0
            ? `${gameTypeSelectedData[0].name}  ${bestOfSelectedData[0].name} require at least ${minChampions} champions`
            : '',
      },
    ],
    canValidate: canValidateChampions,
    setCanValidate: setCanValidateChampions,
    validationStart: ValidationStart.onChange,
  });
  const selectChampionProps: ISelectProps = {
    dataList: {
      data: myTemplateData?.data?.champion || [],
      dataLoading: loading,
      dataLoadError: error,
      valueField: 'name',
      selectedData: championSelectedData,
      onSelectedDataChange: selectChampionHandler,
      showSelected: true,
      multiselect: true,
      type: DataListTypes.Grid,
      columnCount: 2,
      validation: championsValidation.props,
      isValid: championsValidation.isValid,
      errors: championsValidation.errors,
    },
    dropdown: {
      opened: championSelectOpened,
      onOpenedChange: setChampionSelectOpened,
      css: {
        '--left': '290px',
        '--top': '-228px',
      },
    },
    modal: {
      children: '',
      cssWindow: {
        backgroundColor: 'transparent',
      },
    },
    caption: 'Champion pool',
    for: 'champions',
    openIn: window.innerWidth >= openInModalWidth ? openIn.Dropdown : openIn.Modal,
  };
  const mapsValidation = new Validation({
    checks: [
      {
        isValid: gameTypeSelectedData.length > 0 && bestOfSelectedData.length > 0 ? mapSelectedData.length >= minMaps : false,
        error:
          gameTypeSelectedData.length > 0 && bestOfSelectedData.length > 0
            ? `${gameTypeSelectedData[0].name} ${bestOfSelectedData[0].name} require at least ${minMaps} maps`
            : '',
      },
    ],
    canValidate: canValidateMaps,
    setCanValidate: setCanValidateMaps,
    validationStart: ValidationStart.onChange,
  });
  const selectMapsProps: ISelectProps = {
    dataList: {
      data: myTemplateData?.data?.map || [],
      dataLoadError: error,
      dataLoading: loading,
      valueField: 'name',
      selectedData: mapSelectedData,
      onSelectedDataChange: selectMapHandler,
      showSelected: true,
      multiselect: true,
      type: DataListTypes.Grid,
      validation: mapsValidation.props,
      isValid: mapsValidation.isValid,
      errors: mapsValidation.errors,
      columnCount: 3,
    },
    dropdown: {
      opened: mapSelectOpened,
      onOpenedChange: setMapSelectOpened,
      css: {
        '--left': '290px',
        '--top': '-152px',
      },
    },
    modal: {
      children: '',
      cssWindow: {
        backgroundColor: 'transparent',
      },
    },
    caption: 'Map pool',
    for: 'maps',
    openIn: window.innerWidth >= openInModalWidth ? openIn.Dropdown : openIn.Modal,
  };
  const templateOptionsEditorProps: ITemplateOptionsEditorProps = {
    dataList: {
      data: myTemplateData?.template ? myTemplateData.template.templateOption.sort((a, b) => b.sort_param - a.sort_param) : [],
      selectedData: templateOptionsSelectedData,
      onSelectedDataChange: changeSelectedTemplateOptionHandler,
      valueField: 'name',
      showSelected: true,
      emptyValue: '[empty option name]',
    },
    classes: 'mt-2',
    createTemplateOption: addNewTemplateOption,
    deleteTemplateOption: deleteTemplateOption,
    upTemplateOption: upTemplateOption,
    downTemplateOption: downTemplateOption,
    disabled: textEditTemplateOptionNameProps.isValid && selectMapsProps.dataList.isValid && selectChampionProps.dataList.isValid ? false : true,
  };
  const selectDefaultTemplateOptionProps: ISelectProps = {
    dataList: {
      data: myTemplateData?.template?.templateOption || [],
      selectedData: defaultTemplateOptionSelectedData,
      onSelectedDataChange: selectDefaultTemplateOptiontHandler,
      valueField: 'name',
      emptyValue: '[empty option name]',
      classes: 'qc-data-list-default-template-option',
    },
    dropdown: {
      opened: defaultTemplateOptionSelectOpened,
      onOpenedChange: setDefaultTemplateOptionSelectOpened,
      disableCloseAnimation: true,
      css: { '--left': '0', '--top': '70px', width: 280 },
      from: DropdownFrom.Bottom,
    },
    caption: 'Default template option',
    openIn: window.innerWidth >= openInModalWidth ? openIn.Dropdown : openIn.Modal,
    arrowIcon: ArrowIcon,
  };

  const radioPrivateProps: IRadioProps = {
    caption: 'Template can use',
    data: isPrivateData,
    checkedItem: isPrivateTemplateValue,
    onCheckedItemChange: privateChangeHandler,
  };

  return (
    <div className="qc-container">
      <div className="qc-content">
        <>
          <div className="qc-content__header">
            <h1>
              {id == '0' ? 'Create template' : 'Edit template'}
              <CSSTransition in={loading} timeout={300} classNames={'qc-loader-container'} unmountOnExit>
                <div className="qc-loader-container qc-content__header-loader">
                  <Loader />
                </div>
              </CSSTransition>
            </h1>
          </div>
          {!loading && error ? (
            <Alert color="red" title="Error" description={error} />
          ) : (
            <CSSTransition in={dataReady} timeout={500} classNames={'use-css-transition'} unmountOnExit>
              <div className="qc-my-template-edit use-css-transition mb-2">
                <div className="d-flex flex-column">
                  <TextEdit {...textEditTemplateNameProps} />
                  <Radio {...radioPrivateProps} classes="mt-2" />
                  <TemplateOptionsEditor {...templateOptionsEditorProps} />
                  <Select {...selectDefaultTemplateOptionProps} classes="mt-2" />
                  <Button
                    color="blue"
                    text={id == '0' ? 'Create template' : 'Save changes'}
                    onClick={saveChangesHandler}
                    classes="mt-2"
                    disabled={
                      textEditTemplateNameProps.isValid &&
                      textEditTemplateOptionNameProps.isValid &&
                      selectMapsProps.dataList.isValid &&
                      selectChampionProps.dataList.isValid
                        ? false
                        : true
                    }
                    disabledWithoutChangeStyles={buttonDisabled}
                  />
                  <Button color="red" text="Cancel" onClick={cancelHandler} classes="mt-2" />
                </div>
                <div className="qc-my-template-edit__split"></div>
                <div className="d-flex flex-column">
                  {templateOptionsSelectedData.length > 0 &&
                    optionNameEditValue !== null &&
                    gameTypeSelectedData &&
                    bestOfSelectedData &&
                    mapAlgoSelectedData &&
                    championAlgoSelectedData &&
                    mapSelectedData &&
                    championSelectedData &&
                    firstMapSelectedData &&
                    firstChampionSelectedData && (
                      <>
                        <TextEdit {...textEditTemplateOptionNameProps} />
                        <SelectGameTypeFull {...selectGameTypeFullProps} classes="mt-2" />
                        <Select {...selectMapsProps} classes="mt-2" />
                        <Select {...selectChampionProps} classes="mt-2" />
                      </>
                    )}
                </div>
              </div>
            </CSSTransition>
          )}
        </>
      </div>
    </div>
  );
};
