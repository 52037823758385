import { useState, useContext, useEffect } from 'react'
import { IProps, mergePropsWithDefault, openIn } from './select.models'
import './select.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown } from '../dropdown/Dropdown'
import { DataList } from '../data-list/DataList'
import { IData } from '../../components/component.models'
import { DataListTypes } from '../data-list/data-list.models'
import { Alert } from '../alert/Alert'
import { ValidationStart } from '../../validator/validation.models'
import { Modal } from '../modal/Modal'
import { ModalContext } from '../modal/ModalContext'

export function Select(props: IProps) {
  // props
  props = mergePropsWithDefault(props)
  // context
  const { open: openModal, close: closeModal } = useContext(ModalContext)
  // hooks
  const [opened, setOpened] = useState(props.dropdown!.opened)
  useEffect(() => {
    if (props.openIn === openIn.Modal) {
      opened ? openModal() : closeModal()
    }
  }, [opened])
  // methods
  const open = () => {
    setOpened(true)
    props.onOpen ? props.onOpen() : void 0
  }
  const close = () => {
    setOpened(false)
    props.onClose ? props.onClose() : void 0
  }
  const toggle = () => {
    opened ? close() : open()
  }
  // handlers
  const selectedDataChangeHandler = (data: IData[]) => {
    if (
      props.dataList.validation?.validationStart === ValidationStart.onChange
    ) {
      props.dataList.validation.setCanValidate!(true)
    }
    if (!props.dataList.multiselect) {
      close()
    }
    props.dataList.onSelectedDataChange(data)
  }
  const openedChangeHandler = (opened: boolean) => {
    setOpened(opened)
  }
  // classes
  const selectClasses: string[] = [
    'qc-select',
    opened ? 'qc-select-opened' : '',
    props.dataList.selectedData.length === 0 ? 'qc-select-empty' : '',
    props.dataList.validation?.canValidate && props.dataList.isValid === false
      ? 'qc-select-error'
      : `qc-select-${props.color}`,
    !props.caption ? 'qc-select-no-caption' : '',
    props.classes || '',
  ].filter((x) => x !== '')

  return (
    <div className={selectClasses.join(' ')} style={props.css}>
      {props.openIn === openIn.Dropdown && (
        <Dropdown
          {...props.dropdown}
          opened={opened}
          onOpenedChange={openedChangeHandler}
        >
          <DataList
            {...props.dataList}
            color={props.color}
            onSelectedDataChange={selectedDataChangeHandler}
          />
        </Dropdown>
      )}
      {props.openIn === openIn.Modal && opened && (
        <Modal onClose={close} {...props.modal}>
          <DataList
            {...props.dataList}
            color={props.color}
            onSelectedDataChange={selectedDataChangeHandler}
          />
        </Modal>
      )}
      <div className="qc-select__input" onClick={toggle}>
        <div className="qc-select__input-container">
          {props.caption && (
            <label className="qc-select__caption">{props.caption}</label>
          )}
          <label className="qc-select__value">
            {props.dataList.selectedData.length > 0
              ? props.dataList.type !== DataListTypes.Default
                ? props.dataList.selectedData.length ===
                  props.dataList.data.length
                  ? 'All'
                  : props.dataList.selectedData.length === 1
                  ? props.dataList.selectedData[0][props.dataList.valueField!]
                  : `Custom (${props.dataList.selectedData.length} ${props.for})`
                : props.dataList.multiselect &&
                  props.dataList.selectedData.length > 1
                ? `${
                    props.dataList.selectedData[0][props.dataList.valueField!]
                  } (+${props.dataList.selectedData.length - 1})`
                : props.dataList.selectedData[0][
                    props.dataList.valueField!
                  ].trim().length === 0 && props.dataList.emptyValue !== null
                ? props.dataList.emptyValue
                : props.dataList.selectedData[0][props.dataList.valueField!]
              : ''}
          </label>
        </div>
        <div className="qc-select__icon">
          <FontAwesomeIcon
            icon={props.arrowIcon!}
            className="qc-select__arrow"
          />
        </div>
      </div>
      {props.openIn !== openIn.Modal && (
        <span className="qc-select__bar"></span>
      )}
      {props.dataList!.validation?.canValidate &&
        props.dataList!.isValid === false && (
          <Alert
            description={props.dataList!.errors![0]}
            color="red"
            classes="mt-1"
          />
        )}
    </div>
  )
}
