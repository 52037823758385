import { CSSProperties } from 'react'

export interface IProps {
  classes?: string
  css?: CSSProperties
  championAlgo: number
  bestOf: number
  gameType: number
  firstMapID: number
  firstChampionID: number
}

export const defaultProps: IProps = {
  championAlgo: 1,
  bestOf: 3,
  gameType: 9,
  firstMapID: 1,
  firstChampionID: 8,
}

export function mergePropsWithDefault(
  props: IProps,
  defaults: IProps = defaultProps
): IProps {
  const cssProps = { ...defaults.css, ...props.css }
  return {
    ...defaults,
    ...props,
    css: cssProps,
  } as IProps
}
