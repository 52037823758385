import { ButtonLikeComponent } from '../button-like-component/ButtonLikeComponent'
import { Button } from '../../componentsKit/button/Button'
import { DataList } from '../../componentsKit/data-list/DataList'
import { Modal } from '../../componentsKit/modal/Modal'
import { PickBanInfo } from '../pick-ban-info/PickBanInfo'
import { IProps } from './next-step-info.model'

export function NextStepInfo(props: IProps) {
  // classes
  const nextStepInfoClasses: string[] = [
    'd-flex flex-column align-items-center w-100',
    'qc-done-steps-info',
    props.classes || '',
  ].filter((x) => x !== '')

  return (
    <div className={nextStepInfoClasses.join(' ')} style={props.css}>
      <div className="qc-hr mt-1"></div>
      {props.role === props.nextStep!.role ? (
        <>
          <ButtonLikeComponent {...props.select} classes="mb-2 mt-2" />
          <Modal>
            <DataList {...props.dataList} />
          </Modal>
        </>
      ) : (
        <PickBanInfo
          step={props.nextStep!}
          waiting={true}
          classes={`qc-pick-ban-info-${props.nextStep!.what} mt-2`}
        />
      )}
      {props.role === props.nextStep!.role &&
        props.dataList.selectedData.length > 0 && (
          <Button {...props.button} classes="qc-next-step mb-2" />
        )}
    </div>
  )
}
