import { useEffect, useState, useContext } from 'react'
import { IProps, mergePropsWithDefault } from './select-gametype-full.models'
import './select-gametype-full.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown } from '../../componentsKit/dropdown/Dropdown'
import { DataList } from '../../componentsKit/data-list/DataList'
import { IData } from '../component.models'
import { Alert } from '../../componentsKit/alert/Alert'
import { MapAlgo } from '../map-algo/MapAlgo'
import { ChampionAlgo } from '../champion-algo/ChampionAlgo'
import { ModalContext } from '../../componentsKit/modal/ModalContext'
import { openIn } from '../../componentsKit/select/select.models'
import { Modal } from '../../componentsKit/modal/Modal'
import { CSSTransition } from 'react-transition-group'

export function SelectGameTypeFull(props: IProps) {
  // props
  props = mergePropsWithDefault(props)
  // context
  const { open: openModal, close: closeModal } = useContext(ModalContext)
  // hooks
  const [opened, setOpened] = useState(props.dropdown!.opened)
  const [lastForMaps, setLastForMaps] = useState<number | null>(null)
  useEffect(() => {
    if (props.openIn === openIn.Modal) {
      opened ? openModal() : closeModal()
    }
  }, [opened])

  const [settingsChanged, setSettingsChanged] = useState(false)
  useEffect(() => {
    setSettingsChanged(true)
  }, [
    props.gameType.selectedData,
    props.bestOf.selectedData,
    props.mapAlgo.selectedData,
    props.championAlgo.selectedData,
    props.firstMap.selectedData,
    props.firstChampion.selectedData,
  ])

  // methods
  const open = () => {
    setOpened(true)
    props.onOpen ? props.onOpen() : void 0
  }
  const close = () => {
    setOpened(false)
    props.onClose ? props.onClose() : void 0
  }
  const toggle = () => {
    opened ? close() : open()
  }
  // handlers
  const selectedGameTypeChangeHandler = (data: IData[]) => {
    setSettingsChanged(false)
    props.gameType.onSelectedDataChange(data)
  }
  const selectedBestOfChangeHandler = (data: IData[]) => {
    setSettingsChanged(false)
    props.bestOf.onSelectedDataChange(data)
  }
  const selectedMapAlgoChangeHandler = (data: IData[]) => {
    setSettingsChanged(false)
    props.mapAlgo.onSelectedDataChange(data)
  }
  const selectedChampionAlgoChangeHandler = (data: IData[]) => {
    setSettingsChanged(false)
    props.championAlgo.onSelectedDataChange(data)
  }
  const selectedFirstMapChangeHandler = (data: IData[]) => {
    setSettingsChanged(false)
    props.firstMap.onSelectedDataChange(data)
  }
  const selectedFirstChampionChangeHandler = (data: IData[]) => {
    setSettingsChanged(false)
    props.firstChampion.onSelectedDataChange(data)
  }
  const openedChangeHandler = (opened: boolean) => {
    setOpened(opened)
  }
  // classes
  const selectClasses: string[] = [
    'qc-select-gametype-full',
    opened ? 'qc-select-gametype-full-opened' : '',
    !props.caption ? 'qc-select-gametype-full-no-caption' : '',
    props.color ? `qc-select-gametype-full-${props.color}` : '',
    props.classes || '',
  ].filter((x) => x !== '')

  return (
    <div className={selectClasses.join(' ')} style={props.css}>
      {props.openIn === openIn.Dropdown && (
        <Dropdown
          {...props.dropdown}
          opened={opened}
          onOpenedChange={openedChangeHandler}
        >
          <div className="qc-select-gametype-full-dropdown">
            <div className="d-flex">
              <div className="d-flex flex-column">
                <div className="qc-select-gametype-full__list">
                  <span className="qc-select-gametype-full__list-title">
                    Game type
                  </span>
                  <DataList
                    {...props.gameType}
                    color={props.color}
                    onSelectedDataChange={selectedGameTypeChangeHandler}
                  />
                </div>
                <div className="qc-select-gametype-full__list pt-0">
                  <span className="qc-select-gametype-full__list-title">
                    Best of
                  </span>
                  <DataList
                    {...props.bestOf}
                    color={props.color}
                    onSelectedDataChange={selectedBestOfChangeHandler}
                  />
                </div>
                <div className="qc-select-gametype-full__list pt-0">
                  <span className="qc-select-gametype-full__list-title">
                    Map method
                  </span>
                  <DataList
                    {...props.mapAlgo}
                    color={props.color}
                    onSelectedDataChange={selectedMapAlgoChangeHandler}
                  />
                </div>
                <div className="qc-select-gametype-full__list pt-0">
                  <span className="qc-select-gametype-full__list-title">
                    Champion method
                  </span>
                  <DataList
                    {...props.championAlgo}
                    color={props.color}
                    onSelectedDataChange={selectedChampionAlgoChangeHandler}
                  />
                </div>
              </div>
              <div className="qc-select-gametype-full__split"></div>
              <div className="d-flex flex-column">
                <div className="qc-select-gametype-full__list">
                  <span className="qc-select-gametype-full__list-title">
                    First for maps
                  </span>
                  <DataList
                    {...props.firstMap}
                    color={props.color}
                    onSelectedDataChange={selectedFirstMapChangeHandler}
                  />
                </div>
                <div className="qc-select-gametype-full__list pt-0">
                  <span className="qc-select-gametype-full__list-title">
                    First for champions
                  </span>
                  <DataList
                    {...props.firstChampion}
                    color={props.color}
                    onSelectedDataChange={selectedFirstChampionChangeHandler}
                  />
                </div>
              </div>

              <div className="qc-select-gametype-full__split"></div>

              <div className="qc-select-gametype-full__preview">
                <span className="qc-select-gametype-full__preview-title">
                  Preview
                </span>
                <div className="qc-select-gametype-full__preview-algo">
                  <div className="qc-select-gametype-full__preview-algo-maps">
                    <span className="qc-select-gametype-full__preview-desc">
                      Maps
                    </span>
                    <CSSTransition
                      in={settingsChanged}
                      timeout={800}
                      classNames={'qc-algo-transition'}
                      unmountOnExit
                    >
                      <div className="qc-algo-transition">
                        {props.bestOfMapAlgo &&
                          props.bestOfMapAlgo.length > 0 &&
                          props.selectedBestOf.length > 0 &&
                          props.selectedGameType.length > 0 &&
                          props.mapAlgo.selectedData.length > 0 &&
                          props.bestOfMapAlgo
                            .filter(
                              (x) =>
                                x.best_ofID === props.selectedBestOf[0].id &&
                                x.game_typeID ===
                                  props.selectedGameType[0].id &&
                                x.map_algoID ===
                                  props.mapAlgo.selectedData[0].map_algoID
                            )
                            .map((x) =>
                              x.min_maps <= props.mapCount ? (
                                <MapAlgo
                                  mapAlgo={x.map_algoID}
                                  mapCount={props.mapCount}
                                  bestOf={props.selectedBestOf[0].map_count}
                                  gameType={props.selectedGameType[0].id}
                                  firstMap={props.firstMap.selectedData[0].id}
                                />
                              ) : (
                                <Alert
                                  description={`No enought maps. Select at least ${x.min_maps} maps`}
                                  color="red"
                                  classes="mt-2 mb-2"
                                  css={{ width: 176 }}
                                />
                              )
                            )}
                      </div>
                    </CSSTransition>
                  </div>
                  <div className="qc-select-gametype-full__preview-algo-champions">
                    <span className="qc-select-gametype-full__preview-desc">
                      Champions
                    </span>
                    <CSSTransition
                      in={settingsChanged}
                      timeout={800}
                      classNames={'qc-algo-transition'}
                      unmountOnExit
                    >
                      <div className="qc-algo-transition">
                        {props.bestOfChampionAlgo &&
                          props.bestOfChampionAlgo.length > 0 &&
                          props.selectedBestOf.length > 0 &&
                          props.selectedGameType.length > 0 &&
                          props.championAlgo.selectedData.length > 0 &&
                          props.bestOfChampionAlgo
                            .filter(
                              (x) =>
                                x.best_ofID === props.selectedBestOf[0].id &&
                                x.game_typeID ===
                                  props.selectedGameType[0].id &&
                                x.champion_algoID ===
                                  props.championAlgo.selectedData[0]
                                    .champion_algoID
                            )
                            .map((x) =>
                              x.min_champions <= props.championCount ? (
                                <ChampionAlgo
                                  championAlgo={x.champion_algoID}
                                  bestOf={props.selectedBestOf[0].map_count}
                                  gameType={props.selectedGameType[0].id}
                                  firstMapID={props.firstMap.selectedData[0].id}
                                  firstChampionID={
                                    props.firstChampion.selectedData[0].id
                                  }
                                />
                              ) : (
                                <Alert
                                  description={`No enought champions. Select at least ${x.min_champions} champions`}
                                  color="red"
                                  classes="mt-2 mb-2"
                                  css={{ width: 232 }}
                                />
                              )
                            )}
                      </div>
                    </CSSTransition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Dropdown>
      )}
      {props.openIn === openIn.Modal && opened && (
        <Modal onClose={close}>
          <div className="qc-select-gametype-full-dropdown">
            <div className="d-flex">
              <div className="d-flex flex-column">
                <div className="qc-select-gametype-full__list">
                  <span className="qc-select-gametype-full__list-title">
                    Game type
                  </span>
                  <DataList
                    {...props.gameType}
                    color={props.color}
                    onSelectedDataChange={selectedGameTypeChangeHandler}
                  />
                </div>
                <div className="qc-select-gametype-full__list pt-0">
                  <span className="qc-select-gametype-full__list-title">
                    Best of
                  </span>
                  <DataList
                    {...props.bestOf}
                    color={props.color}
                    onSelectedDataChange={selectedBestOfChangeHandler}
                  />
                </div>
                <div className="qc-select-gametype-full__list pt-0">
                  <span className="qc-select-gametype-full__list-title">
                    Map method
                  </span>
                  <DataList
                    {...props.mapAlgo}
                    color={props.color}
                    onSelectedDataChange={selectedMapAlgoChangeHandler}
                  />
                </div>
                <div className="qc-select-gametype-full__list pt-0">
                  <span className="qc-select-gametype-full__list-title">
                    Champion method
                  </span>
                  <DataList
                    {...props.championAlgo}
                    color={props.color}
                    onSelectedDataChange={selectedChampionAlgoChangeHandler}
                  />
                </div>
                <div className="qc-select-gametype-full__list pt-0">
                  <span className="qc-select-gametype-full__list-title">
                    First for maps
                  </span>
                  <DataList
                    {...props.firstMap}
                    color={props.color}
                    onSelectedDataChange={selectedFirstMapChangeHandler}
                  />
                </div>
                <div className="qc-select-gametype-full__list pt-0">
                  <span className="qc-select-gametype-full__list-title">
                    First for champions
                  </span>
                  <DataList
                    {...props.firstChampion}
                    color={props.color}
                    onSelectedDataChange={selectedFirstChampionChangeHandler}
                  />
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <div className="qc-select-gametype-full__input" onClick={toggle}>
        <div className="qc-select-gametype-full__input-container">
          {props.caption && (
            <label className="qc-select-gametype-full__caption">
              {props.caption}
            </label>
          )}
          <label className="qc-select-gametype-full__value">
            {props.gameType.selectedData.length > 0
              ? `${
                  props.gameType.selectedData[0][props.gameType.valueField!]
                } ${props.bestOf.selectedData[0][props.bestOf.valueField!]}`
              : ''}
          </label>
        </div>
        <div className="qc-select-gametype-full__icon">
          <FontAwesomeIcon
            icon={props.arrowIcon!}
            className="qc-select-gametype-full__arrow"
          />
        </div>
      </div>
      {props.openIn !== openIn.Modal && (
        <span className="qc-select-gametype-full__bar"></span>
      )}
    </div>
  )
}
