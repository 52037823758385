import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { Button } from '../../componentsKitTest/button/Button'
import styles from './Button.module.scss'

export function DiscordSignButton() {
  const signIn = () => {
    const host =
      process.env.NODE_ENV !== 'production'
        ? `${process.env.REACT_APP_SERVER_ENDPOINT_DEV}/discord/auth/`
        : `${window.location.origin}/discord/auth/`
    const discortClientId = process.env.REACT_APP_DISCORD_CLIENT_ID
    window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${discortClientId}&redirect_uri=${encodeURIComponent(
      host || ''
    )}&response_type=code&scope=identify`
  }
  return <Button color="indigo" text="Sign In" classes={styles.root} iconLeft={faDiscord} onClick={signIn} />
}
