import './session-info.scss'
import { IProps } from './session-info.model'
import { NavLink } from 'react-router-dom'

export function SessionInfo(props: IProps) {
  // classes
  const pickBanInfoClasses: string[] = [
    'qc-session-info',
    props.classes || '',
  ].filter((x) => x !== '')

  // const
  let dateCreate = new Date(props.dateCreate!)
  let yyyy = new Intl.DateTimeFormat('ru', { year: 'numeric' }).format(
    dateCreate
  )
  let MM = new Intl.DateTimeFormat('ru', { month: 'numeric' }).format(
    dateCreate
  )
  MM = MM.length === 1 ? `0${MM}` : MM
  let dd = new Intl.DateTimeFormat('ru', { day: 'numeric' }).format(dateCreate)
  dd = dd.length === 1 ? `0${dd}` : dd
  let HH = new Intl.DateTimeFormat('ru', { hour: 'numeric' }).format(dateCreate)
  HH = HH.length === 1 ? `0${HH}` : HH
  let mm = new Intl.DateTimeFormat('ru', { minute: 'numeric' }).format(
    dateCreate
  )
  mm = mm.length === 1 ? `0${mm}` : mm

  const link = props.playerCode
    ? `/session/${props.playerCode}/${props.player1}`
    : `/session/${props.spectatorCode}`

  return (
    <NavLink to={link} className="qc-nav">
      <div className={pickBanInfoClasses.join(' ')} style={props.css}>
        <div className="qc-session-info__body">
          <div className="qc-session-info-title">
            {props.player1} vs {props.player2}
          </div>
          <span className="qc-session-info-game-type mt-1">
            {props.template ? (
              <>
                {props.template} {props.templateOption}
              </>
            ) : (
              <>
                {props.gameType} {props.bestOf}
              </>
            )}
          </span>
          <div className="d-flex mt-1">
            <span className="qc-session-info-create-date">
              {`${dd}.${MM}.${yyyy}`}
            </span>
            <span className="qc-session-info-create-time">{`${HH}:${mm}`}</span>
          </div>
        </div>
      </div>
    </NavLink>
  )
}
