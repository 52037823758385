import './pick-ban-info-for-map.scss'
import { IProps } from './pick-ban-info-for-map.model'
import { mapImage } from '../../images/maps/maps'

export function PickBanInfoForMap(props: IProps) {
  // classes
  const pickBanInfoForMapClasses: string[] = [
    'qc-pick-ban-info-for-map',
    props.classes || '',
  ].filter((x) => x !== '')

  const getImage = (): React.ReactNode => {
    if(!(props.title in mapImage)){
      return <></>
    }
    const img = new Image()
    img.src = mapImage[props.title]
    return <img src={img.src} alt="" className="qc-pick-ban-info-for-map__body-title-image" />
  }

  return (
    <div className={pickBanInfoForMapClasses.join(' ')} style={props.css}>
      <div className="qc-pick-ban-info-for-map__body">
        <div className="qc-pick-ban-info-for-map__body-title">
          <div className="qc-pick-ban-info-for-map__body-title-text">{props.title}</div>
          {getImage()}
        </div>
        {props.children}
      </div>
    </div>
  )
}
