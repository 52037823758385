import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
import { faChevronRight as ArrowIcon } from '@fortawesome/free-solid-svg-icons'
import { CSSProperties } from 'react'
import {
  IBestOfChampionAlgo,
  IBestOfMapAlgo,
  IFirst,
} from '../../hooks/dataForCreateSession.hook'
import { IData } from '../component.models'
import {
  IProps as IDataListProps,
  mergePropsWithDefault as mergeDataListPropsWithDefault,
  defaultProps as dataListDefaults,
} from '../../componentsKit/data-list/data-list.models'
import {
  IProps as IDropdownProps,
  mergePropsWithDefault as mergeDropdownPropsWithDefault,
} from '../../componentsKit/dropdown/dropdown.models'
import { openIn } from '../../componentsKit/select/select.models'

export interface IProps {
  classes?: string
  css?: CSSProperties
  gameType: IDataListProps
  bestOf: IDataListProps
  mapAlgo: IDataListProps
  championAlgo: IDataListProps
  firstMap: IDataListProps
  firstChampion: IDataListProps
  dropdown?: IDropdownProps
  caption?: string
  arrowIcon?: IconDefinition
  color?: string
  mapCount: number
  championCount: number
  bestOfMapAlgo?: IBestOfMapAlgo[]
  bestOfChampionAlgo?: IBestOfChampionAlgo[]
  first?: IFirst[]
  selectedBestOf: IData[]
  selectedGameType: IData[]
  openIn?: openIn
  onOpen?: () => void
  onClose?: () => void
}

export const defaultProps: IProps = {
  gameType: {
    data: [] as IData[],
    selectedData: [] as IData[],
    onSelectedDataChange: (data: IData[]) => void 0,
    multiselect: false,
    showSelected: true,
  },
  bestOf: {
    data: [] as IData[],
    selectedData: [] as IData[],
    onSelectedDataChange: (data: IData[]) => void 0,
    multiselect: false,
    showSelected: true,
  },
  mapAlgo: {
    data: [] as IData[],
    selectedData: [] as IData[],
    onSelectedDataChange: (data: IData[]) => void 0,
    multiselect: false,
    showSelected: true,
  },
  championAlgo: {
    data: [] as IData[],
    selectedData: [] as IData[],
    onSelectedDataChange: (data: IData[]) => void 0,
    multiselect: false,
    showSelected: true,
  },
  firstMap: {
    data: [] as IData[],
    selectedData: [] as IData[],
    onSelectedDataChange: (data: IData[]) => void 0,
    multiselect: false,
    showSelected: true,
  },
  firstChampion: {
    data: [] as IData[],
    selectedData: [] as IData[],
    onSelectedDataChange: (data: IData[]) => void 0,
    multiselect: false,
    showSelected: true,
  },
  dropdown: {
    opened: false,
    onOpenedChange: () => void 0,
    classes: 'd-flex',
  },
  arrowIcon: ArrowIcon,
  color: 'green',
  mapCount: 7,
  championCount: 16,
  selectedBestOf: [],
  selectedGameType: [],
  openIn: openIn.Dropdown,
}

export function mergePropsWithDefault(
  props: IProps,
  defaults: IProps = defaultProps
): IProps {
  const cssProps = { ...defaults.css, ...props.css }
  let gameTypeProps = mergeDataListPropsWithDefault(
    defaults.gameType,
    dataListDefaults
  )
  gameTypeProps = mergeDataListPropsWithDefault(props.gameType, gameTypeProps)
  //
  let bestOfProps = mergeDataListPropsWithDefault(
    defaults.bestOf,
    dataListDefaults
  )
  bestOfProps = mergeDataListPropsWithDefault(props.bestOf, bestOfProps)
  //
  let mapAlgoProps = mergeDataListPropsWithDefault(
    defaults.mapAlgo,
    dataListDefaults
  )
  mapAlgoProps = mergeDataListPropsWithDefault(props.mapAlgo, mapAlgoProps)
  //
  let championAlgoProps = mergeDataListPropsWithDefault(
    defaults.championAlgo,
    dataListDefaults
  )
  championAlgoProps = mergeDataListPropsWithDefault(
    props.championAlgo,
    championAlgoProps
  )
  //
  let firstMapProps = mergeDataListPropsWithDefault(
    defaults.firstMap,
    dataListDefaults
  )
  firstMapProps = mergeDataListPropsWithDefault(props.firstMap, firstMapProps)
  //
  let firstChampionProps = mergeDataListPropsWithDefault(
    defaults.firstChampion,
    dataListDefaults
  )
  firstChampionProps = mergeDataListPropsWithDefault(
    props.firstChampion,
    firstChampionProps
  )
  //
  const dropdownProps = props.dropdown
    ? mergeDropdownPropsWithDefault(props.dropdown, defaults.dropdown)
    : defaults.dropdown

  return {
    ...defaults,
    ...props,
    gameType: gameTypeProps,
    bestOf: bestOfProps,
    mapAlgo: mapAlgoProps,
    championAlgo: championAlgoProps,
    firstMap: firstMapProps,
    firstChampion: firstChampionProps,
    dropdown: dropdownProps,
    css: cssProps,
  } as IProps
}
