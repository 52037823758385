import { useEffect, useState, useContext } from 'react'
import axios, { AxiosError } from 'axios'
import { AuthContext } from '../context/AuthContext'

interface IGameType {
  name: string
}

interface IBestOf {
  name: string
}

export interface ISession {
  player1: string
  player2: string
  player_code: string
  dt_create: string
  gameType: IGameType
  bestOf: IBestOf
  template_name: string | null
  template_option_name: string | null
  template_bracket_link: string | null
}

export function useMySessions() {
  const [sessions, setSessions] = useState<ISession[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const { token } = useContext(AuthContext)

  async function fetchSessions() {
    try {
      setError('')
      setLoading(true)
      const response = await axios.get<ISession[]>('/my-sessions/getMySessions', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      setSessions(response.data)
      setLoading(false)
    } catch (e) {
      const error = e as AxiosError
      setLoading(false)
      setError(error.message || 'Fetch data error')
    }
  }

  useEffect(() => {
    fetchSessions()
  }, [])

  return { sessions, loading, error }
}
