import { CSSProperties } from 'react'
import { IData } from '../component.models'
import {
  DataListTypes,
  IProps as IDataListProps,
  mergePropsWithDefault as mergeDataListPropsWithDefault,
  defaultProps as dataListDefaults,
} from '../../componentsKit/data-list/data-list.models'

export interface IProps {
  classes?: string
  css?: CSSProperties
  dataList: IDataListProps
  caption?: string
  color?: string
  disabled?: boolean
  //
  createTemplateOption: () => void
  deleteTemplateOption: () => void
  upTemplateOption: () => void
  downTemplateOption: () => void
}

export const defaultProps: IProps = {
  dataList: {
    data: [] as IData[],
    selectedData: [] as IData[],
    onSelectedDataChange: (data: IData[]) => void 0,
    multiselect: false,
    type: DataListTypes.Default,
  },
  color: 'green',
  caption: 'Template options',
  createTemplateOption: () => void 0,
  deleteTemplateOption: () => void 0,
  upTemplateOption: () => void 0,
  downTemplateOption: () => void 0,
}

export function mergePropsWithDefault(
  props: IProps,
  defaults: IProps = defaultProps
): IProps {
  const cssProps = { ...defaults.css, ...props.css }
  if (props.css?.width) {
    if (!props.dataList.css) {
      props.dataList.css = {} as CSSProperties
    }
    props.dataList.css.width = props.css.width
  }
  let dataListProps = mergeDataListPropsWithDefault(
    defaults.dataList,
    dataListDefaults
  )
  dataListProps = mergeDataListPropsWithDefault(props.dataList, dataListProps)

  return {
    ...defaults,
    ...props,
    dataList: dataListProps,
    css: cssProps,
  } as IProps
}
