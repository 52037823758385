import { PickBanInfo } from '../pick-ban-info/PickBanInfo'
import { IProps } from './done-steps-info.model'

export function DoneStepsInfo(props: IProps) {
  // classes
  const doneStepsInfoClasses: string[] = [
    'qc-done-steps-info',
    props.classes || '',
  ].filter((x) => x !== '')

  return (
    <div className={doneStepsInfoClasses.join(' ')} style={props.css}>
      {props.steps.map((step) => (
        <PickBanInfo step={step} classes={`qc-pick-ban-info-${step.what}`} />
      ))}
    </div>
  )
}
